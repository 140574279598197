import { all, call, delay, put, takeLatest } from '@redux-saga/core/effects';
import { loginPayPalAccount, paypalLoginCallback } from '../../../api/paypal';
import {
  addToursToProfile,
  connectStripe,
  fetchActiveTours,
  fetchUserProfileById,
  updateProfile,
  uploadUserProfileImage
} from '../../../api/taptotip';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

const mapUserProfile = ({ profile, tours, features, paypal, _id }) => {
  return {
    ...profile,
    tours,
    defaultPaymentAmount: profile?.defaultPaymentAmount / 100 || 0,
    country: profile.country?.toLowerCase() || 'ie',
    features,
    paypal,
    userId: _id
  };
};

const mapProfileForUpdate = profile => ({
  ...profile,
  defaultPaymentAmount: profile?.defaultPaymentAmount * 100
});

function* fetchProfileWorker({ payload }) {
  try {
    const profile = yield call(fetchUserProfileById, payload);

    yield put(actions.fetchProfileSuccess(mapUserProfile(profile)));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* updateProfileWorker({ payload: { userId, profile } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    const response = yield all([
      call(updateProfile, userId, mapProfileForUpdate(profile)),
      delay(1000)
    ]);

    yield put(
      actions.updateProfileSuccess({
        ...response[0],
        defaultPaymentAmount: response[0].defaultPaymentAmount / 100
      })
    );
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* uploadUserProfileImageWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));

    const response = yield all([call(uploadUserProfileImage, payload), delay(1000)]);

    yield put(actions.uploadUserProfileImageSuccess(response[0].url));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    yield put(actions.facedError(err));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* connectStripeWorker() {
  try {
    const response = yield call(connectStripe);

    yield put(actions.connectStripeSuccess(response));
  } catch (err) {
    if (err.status !== 400) {
      yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
    }
  }
}

function* fetchActiveToursWorker() {
  try {
    const activeTours = yield call(fetchActiveTours);

    yield put(actions.fetchActiveToursSuccess(activeTours));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* addToursToProfileWorker({ payload: { userId, tourIds } }) {
  try {
    yield call(addToursToProfile, userId, tourIds);

    yield put(actions.fetchAgentsProfiles());
    yield put(actions.fetchProfile(userId));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* getPayPalLoginUrlWorker() {
  try {
    const { loginUrl } = yield call(loginPayPalAccount);

    yield put(actions.getPayPalLoginUrlSuccess(loginUrl));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* paypalLoginCallbackWorker({ payload }) {
  try {
    const paypalAccountInfo = yield call(paypalLoginCallback, payload);

    yield put(actions.paypalLoginCallbackSuccess(paypalAccountInfo));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_PROFILE, fetchProfileWorker);
  yield takeLatest(types.UPDATE_PROFILE, updateProfileWorker);
  yield takeLatest(types.UPLOAD_USER_PROFILE_IMAGE, uploadUserProfileImageWorker);
  yield takeLatest(types.CONNECT_STRIPE, connectStripeWorker);
  yield takeLatest(types.FETCH_ACTIVE_TOURS, fetchActiveToursWorker);
  yield takeLatest(types.ADD_TOURS_TO_PROFILE, addToursToProfileWorker);
  yield takeLatest(types.GET_PAYPAL_LOGIN_URL, getPayPalLoginUrlWorker);
  yield takeLatest(types.PAYPAL_LOGIN_CALLBACK, paypalLoginCallbackWorker);
}
