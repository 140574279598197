import { Analytics } from '@analytics';
import ResourcesBlankSlate from '@components/BlankSlates/ResourcesBlankSlate';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import CreateOrEditTipDirectResource from '@components/TapToTip/CreateOrEditTipDirectResource';
import ListResources from '@components/TapToTip/ListResources';
import { StyleBreakpoints } from '@constants';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import reducer from './reducer';
import saga from './saga';

const ResourceActiveScreen = {
  ResourcesList: 'ResourcesList',
  CreateResource: 'CreateResource'
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  contentContainer: {
    padding: '24px',
    flexWrap: 'wrap',
    height: 'fit-content',
    alignContent: 'flex-start',
    justifyContent: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      alignItems: 'flex-start',
      width: '80%'
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    width: '100%'
  }
}));

const TippingResources = ({
  resources,
  fetchResources,
  createResource,
  deleteResource,
  editResource,
  isLoading
}) => {
  const classes = useStyles();
  useInjectReducer({ key: 'tipdirectResources', reducer });
  useInjectSaga({ key: 'tipdirectResources', saga });

  const [activeScreen, setActiveScreen] = useState(ResourceActiveScreen.ResourcesList);
  const [resourceToEdit, setResourceToEdit] = useState();

  useEffect(() => {
    if (!resources) {
      fetchResources();
    }
  }, []);

  const openCreateResources = () => {
    setResourceToEdit(null);
    Analytics.trackViewFeature('Resources');
    Analytics.track('open create resource');
    setActiveScreen(ResourceActiveScreen.CreateResource);
  };

  const openEditResource = resource => {
    Analytics.trackViewFeature('Resources');
    setResourceToEdit(resource);
    setActiveScreen(ResourceActiveScreen.CreateResource);
  };

  const submitCreateResource = async (name, description, emoji) => {
    Analytics.track('create resource');
    Analytics.trackUseFeature('Resources');
    await createResource({ name, description, emoji });
    setActiveScreen(ResourceActiveScreen.ResourcesList);
  };

  const submitEditResource = async ({ id, name, description, emoji }) => {
    Analytics.track('edit resource');
    Analytics.trackViewFeature('Resources');
    await editResource({ id, name, description, emoji });
    setActiveScreen(ResourceActiveScreen.ResourcesList);
  };

  if (isLoading) {
    return (
      <>
        <TopNav />
        <div className={classes.container}>
          <TipDirectLeftMenu selectedTab={'Resources'} />

          <div className={classes.contentContainer}>
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <TopNav />
      <div className={classes.container}>
        <TipDirectLeftMenu selectedTab={'Resources'} />

        <div className={classes.contentContainer}>
          {resources?.length === 0 && activeScreen !== ResourceActiveScreen.CreateResource && (
            <ResourcesBlankSlate openCreateResources={openCreateResources} />
          )}

          {Boolean(resources?.length) && activeScreen === ResourceActiveScreen.ResourcesList && (
            <ListResources
              deleteResource={deleteResource}
              openEditResource={openEditResource}
              resources={resources}
              openCreateResources={openCreateResources}
            />
          )}

          {activeScreen === ResourceActiveScreen.CreateResource && (
            <CreateOrEditTipDirectResource
              existingResource={resourceToEdit}
              submitCreateResource={submitCreateResource}
              closeCreateResource={() => {
                setActiveScreen(ResourceActiveScreen.ResourcesList);
              }}
              editResource={submitEditResource}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  resources: state.tipdirectResources?.resources,
  isLoading: state.tipdirectResources?.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchResources: () => dispatch(actions.fetchResources()),
  deleteResource: payload => dispatch(actions.deleteResource(payload)),
  editResource: payload => dispatch(actions.editResource(payload)),
  createResource: payload => dispatch(actions.createResource(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TippingResources);
