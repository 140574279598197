import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AgentsProfilesTable';

export default defineMessages({
  sendInvite: {
    id: `${scope}.sendInvite`,
    defaultMessage: 'Send Invite'
  },
  emailAddress: {
    id: `${scope}.emailAddress`,
    defaultMessage: 'Email Address'
  },
  placeholder: {
    id: `${scope}.placeholder`,
    defaultMessage: 'Type or paste email addresses and press "Enter"...'
  },
  inviteGuide: {
    id: `${scope}.inviteGuide`,
    defaultMessage: 'Invite Guides to'
  },
  enterAnEmail: {
    id: `${scope}.enterAnEmail`,
    defaultMessage: 'Enter an email address below to send an invite'
  },
  recommendEnterAnEmail: {
    id: `${scope}.recommend_enter_an_email`,
    defaultMessage: 'Enter an email address below to send a recommendation email'
  },
  nameNotSet: {
    id: `${scope}.nameNotSet`,
    defaultMessage: 'Company Name Not Set'
  },
  setName: {
    id: `${scope}.setName`,
    defaultMessage: 'Set Name'
  },
  recommendTitle: {
    id: `${scope}.recommend_title`,
    defaultMessage: 'Recommend Tip Direct others'
  },
  dragAndDrop: {
    id: `${scope}.dragAndDrop`,
    defaultMessage: 'Drag and drop a CSV file here or click'
  },
  inviteLinkCopied: {
    id: `${scope}.inviteLinkCopied`,
    defaultMessage: 'Invite link copied to clipboard'
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: 'Copy Link'
  },
  tooltipTitle: {
    id: `${scope}.tooltipTitle`,
    defaultMessage: 'People with this link can join your company'
  },
  companyInviteLink: {
    id: `${scope}.companyInviteLink`,
    defaultMessage: 'Company Invite Link'
  },
  downloadQRCode: {
    id: `${scope}.downloadQRCode`,
    defaultMessage: 'Download QR Code'
  }
});
