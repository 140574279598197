import { all, call, delay, put, takeLatest } from '@redux-saga/core/effects';
import { loginPayPalAccount, paypalLoginCallback } from '../../api/paypal';
import {
  addTapToTipTour,
  addToursToProfile,
  connectStripe,
  editTapToTipTour,
  fetchActiveTours,
  fetchAgentsProfiles,
  fetchReviewsStatistic,
  fetchTransactions,
  fetchUserProfileById,
  multipleAssign,
  revokeInvite,
  sendInvitesForGuides,
  updateProfile,
  uploadUserProfileImage
} from '../../api/taptotip';
import { deleteTour, fetchAvailableTours } from '../../api/tour';
import { date2str } from '../../utils/helpers';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

const mapUserProfile = ({ profile, tours, features, paypal }) => {
  return {
    ...profile,
    tours,
    defaultPaymentAmount: profile?.defaultPaymentAmount / 100 || 0,
    country: profile.country?.toLowerCase() || 'ie',
    features,
    paypal
  };
};

const mapProfileForUpdate = profile => ({
  ...profile,
  defaultPaymentAmount: profile.defaultPaymentAmount * 100
});

function normalizeDateStrings(payload) {
  return {
    ...payload,
    dateRange: {
      start: date2str(payload.dateRange.start),
      end: date2str(payload.dateRange.end)
    }
  };
}

function* fetchTransactionsWorker({ payload = null }) {
  try {
    const transactions = yield call(fetchTransactions, payload);

    yield put(actions.fetchTransactionsSuccess(transactions));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchProfileWorker({ payload }) {
  try {
    const profile = yield call(fetchUserProfileById, payload);

    yield put(actions.fetchProfileSuccess(mapUserProfile(profile)));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* updateProfileWorker({ payload: { userId, profile } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    const response = yield all([
      call(updateProfile, userId, mapProfileForUpdate(profile)),
      delay(1000)
    ]);

    yield put(
      actions.updateProfileSuccess({
        ...response[0],
        defaultPaymentAmount: response[0].defaultPaymentAmount / 100
      })
    );
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* uploadUserProfileImageWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));

    const response = yield all([call(uploadUserProfileImage, payload), delay(1000)]);

    yield put(actions.uploadUserProfileImageSuccess(response[0].url));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    yield put(actions.facedError(err));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* connectStripeWorker() {
  try {
    const response = yield call(connectStripe);

    yield put(actions.connectStripeSuccess(response));
  } catch (err) {
    if (err.status !== 400) {
      yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
    }
  }
}

function* fetchAgentsProfilesWorker() {
  try {
    const agentsProfiles = yield call(fetchAgentsProfiles);

    yield put(actions.fetchAgentsProfilesSuccess(agentsProfiles));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchSendInvitesWorker({ payload }) {
  try {
    yield call(sendInvitesForGuides, payload);
    yield call(fetchAgentsProfilesWorker);
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* revokeInviteWorker({ payload }) {
  try {
    yield call(revokeInvite, payload);
    yield call(fetchAgentsProfilesWorker);
    yield put(setSnackbarStatus(snackbarStatus.inviteDeleted));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchActiveToursWorker() {
  try {
    const activeTours = yield call(fetchActiveTours);

    yield put(actions.fetchActiveToursSuccess(activeTours));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchToursWorker() {
  try {
    const toursResponse = yield call(fetchAvailableTours, 'active');
    yield put(actions.fetchToursSuccess(toursResponse.data.data.tours));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* addToursToProfileWorker({ payload: { userId, tourIds } }) {
  try {
    yield call(addToursToProfile, userId, tourIds);

    yield put(actions.fetchAgentsProfiles());
    yield put(actions.fetchProfile(userId));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* addTapToTipTourWorker({ payload }) {
  try {
    yield call(addTapToTipTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchActiveTours());
    yield fetchToursWorker();
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* editTapToTipTourWorker({ payload }) {
  try {
    yield call(editTapToTipTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchActiveTours());
    yield fetchToursWorker();
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchReviewsStatisticWorker({ payload }) {
  try {
    const result = yield call(fetchReviewsStatistic, normalizeDateStrings(payload));
    yield put(actions.fetchReviewsStatisticSuccess(result));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}


function* getPayPalLoginUrlWorker() {
  try {
    const { loginUrl } = yield call(loginPayPalAccount);

    yield put(actions.getPayPalLoginUrlSuccess(loginUrl));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* paypalLoginCallbackWorker({ payload }) {
  try {
    const paypalAccountInfo = yield call(paypalLoginCallback, payload);

    yield put(actions.paypalLoginCallbackSuccess(paypalAccountInfo));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* multipleAssignWorker({ payload: { tourIds, guideIds } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    yield call(multipleAssign, tourIds, guideIds);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchAgentsProfiles());
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* deleteTourWorker({ payload }) {
  try {
    yield call(deleteTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchActiveTours());
    yield fetchToursWorker();
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.details)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_TRANSACTIONS, fetchTransactionsWorker);
  yield takeLatest(types.FETCH_PROFILE, fetchProfileWorker);
  yield takeLatest(types.UPDATE_PROFILE, updateProfileWorker);
  yield takeLatest(types.UPLOAD_USER_PROFILE_IMAGE, uploadUserProfileImageWorker);
  yield takeLatest(types.CONNECT_STRIPE, connectStripeWorker);
  yield takeLatest(types.FETCH_AGENTS_PROFILES, fetchAgentsProfilesWorker);
  yield takeLatest(types.SEND_GUIDE_INVITES, fetchSendInvitesWorker);
  yield takeLatest(types.FETCH_ACTIVE_TOURS, fetchActiveToursWorker);
  yield takeLatest(types.REVOKE_INVITE, revokeInviteWorker);
  yield takeLatest(types.ADD_TOURS_TO_PROFILE, addToursToProfileWorker);
  yield takeLatest(types.ADD_TTT_TOUR, addTapToTipTourWorker);
  yield takeLatest(types.EDIT_TTT_TOUR, editTapToTipTourWorker);
  yield takeLatest(types.FETCH_TOURS, fetchToursWorker);
  yield takeLatest(types.FETCH_REVIEWS_STATISTIC, fetchReviewsStatisticWorker);
  yield takeLatest(types.GET_PAYPAL_LOGIN_URL, getPayPalLoginUrlWorker);
  yield takeLatest(types.PAYPAL_LOGIN_CALLBACK, paypalLoginCallbackWorker);
  yield takeLatest(types.MULTIPLE_ASSIGN, multipleAssignWorker);
  yield takeLatest(types.DELETE_TOUR, deleteTourWorker);
}
