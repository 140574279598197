import { Button, CircularProgress, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { RemitVenmoPaymentIntentIfAble, createVenmoAccount, getPayoutLink } from '../../api/payout';
import { loginPayPalAccount } from '../../api/paypal';
import claimtip from '../../assets/images/payout_page/Money.svg';
import phoneicon from '../../assets/images/payout_page/Phone.png';
import warningicon from '../../assets/images/warning.svg';
import AstraInfoCollect from './AstraInfoCollect';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  container: {},
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto'
  },
  paymentMethods: {
    border: '1px solid red'
  },
  outerSurface: {
    backgroundColor: '#f9f9f9',
    padding: 22,
    margin: 11,
    borderRadius: 16
  },
  innerSurface: {
    backgroundColor: 'white',
    padding: 12,
    paddingLeft: 12,
    borderRadius: 16
  },
  innerSurfaceButton: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    padding: 12,
    borderRadius: 16,
    marginBottom: '16px'
  },
  boldHeading: {
    color: 'black',
    fontWeight: 'bold'
  },
  imageStyle: {
    margin: 22
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonStyle: {
    padding: 8
  },
  iconImage: {
    width: '35px',
    height: '35px'
  },
  iconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    borderRadius: 35,
    marginRight: 12,
    width: '35px',
    height: '35px'
  },
  payoutLinkBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.22px',
    color: '#000'
  },
  iconAndLabel: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function getPaymentProcessorsForCountry(country, payoutlink) {
  if (process.env.REGION === 'QA') {
    return [
      {
        name: 'Revolut',
        icon: require('../../assets/images/payout_page/Revolut.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Visa Debit Card',
        icon: require('../../assets/images/payout_page/Visa.svg'),
        link: payoutlink ?? '',
        type: 'Astra'
      },
      {
        name: 'MasterCard Debit Card',
        icon: require('../../assets/images/payout_page/Mastercard.svg'),
        link: payoutlink ?? '',
        type: 'Astra'
      },
      {
        name: 'PayPal',
        icon: require('../../assets/images/payout_page/PayPal.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Venmo',
        icon: require('../../assets/images/payout_page/Venmo.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Bank Transfer',
        icon: require('../../assets/images/payout_page/Transfer.svg'),
        link: payoutlink ?? ''
      }
    ];
  }

  if (process.env.REGION === 'US' && country === 'ca') {
    return [
      {
        name: 'Bank Transfer',
        icon: require('../../assets/images/payout_page/Transfer.svg'),
        link: payoutlink ?? ''
      }
    ];
  }

  if (process.env.REGION === 'US' && country === 'us') {
    return [
      {
        name: 'Visa Debit Card',
        icon: require('../../assets/images/payout_page/Visa.svg'),
        link: payoutlink ?? '',
        type: 'Astra'
      },
      {
        name: 'MasterCard Debit Card',
        icon: require('../../assets/images/payout_page/Mastercard.svg'),
        link: payoutlink ?? '',
        type: 'Astra'
      },
      {
        name: 'PayPal',
        icon: require('../../assets/images/payout_page/PayPal.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Venmo',
        icon: require('../../assets/images/payout_page/Venmo.svg'),
        link: payoutlink ?? ''
      }
    ];
  }

  if (process.env.REGION === 'EU') {
    return [
      {
        name: 'Revolut',
        icon: require('../../assets/images/payout_page/Revolut.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Visa Debit Card',
        icon: require('../../assets/images/payout_page/Visa.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'MasterCard Debit Card',
        icon: require('../../assets/images/payout_page/Mastercard.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'PayPal',
        icon: require('../../assets/images/payout_page/PayPal.svg'),
        link: payoutlink ?? ''
      },
      {
        name: 'Bank Transfer',
        icon: require('../../assets/images/payout_page/Transfer.svg'),
        link: payoutlink ?? ''
      }
    ];
  }

  return [];
}

const PayoutLink = ({ user }) => {
  const classes = useStyles();

  useEffect(getPayoutLinkInfo, []);
  let [payoutLink, setPayoutLink] = useState(null);
  let [errorMessage, setErrorMessage] = useState(null);
  let [renderVenmo, setRenderVenmo] = useState(false);
  let [showAstraInfoCollect, setShowAstraInfoCollect] = useState(false);
  let [venmoSuccess, setVenmoSuccess] = useState(false);
  let [userPhone, setUserPhone] = useState(false);
  let [errorText, setErrorText] = useState('');
  let [payoutLinks, setPayoutLinks] = useState(
    getPaymentProcessorsForCountry(user.profile.country)
  );

  //If payout-link id in url display revolut payout links otherwise just display paypal

  async function getPayoutLinkInfo() {
    let payoutLinkResp = await getPayoutLink();
    if (payoutLinkResp) {
      setPayoutLink(payoutLinkResp);
      setPayoutLinks(
        getPaymentProcessorsForCountry(user.profile.country, payoutLinkResp.payoutLinkBody.url)
      );
    } else {
      setErrorMessage(
        'A valid payout could not be retrieved for this user. Please ensure that you are signed in as the user that received the tip.'
      );
    }
  }
  async function submitVenmo() {
    //let payoutLinkResp = await getPayoutLink();
    let accountResp = await createVenmoAccount(userPhone);
    try {
      await RemitVenmoPaymentIntentIfAble();
      setVenmoSuccess(true);
    } catch (e) {
      console.log('caught e', e);
      setErrorText('Payout Link could not be redeamed, please contact support@tripadmit.com');
    }
  }

  async function loginWithPayPal() {
    let resp = await loginPayPalAccount();
    window.location.href = resp.loginUrl;
  }

  function renderPayoutLinkButton(payoutLink) {
    if (payoutLink.name == 'PayPal') {
      return (
        <Button
          className={classes.buttonStyle}
          size="large"
          color={'primary'}
          onClick={loginWithPayPal}>
          Select
        </Button>
      );
    }
    if (payoutLink.name == 'Venmo') {
      return (
        <Button
          className={classes.buttonStyle}
          size="large"
          color={'primary'}
          onClick={() => setRenderVenmo(true)}>
          Select
        </Button>
      );
    }
    if (payoutLink.type == 'Astra') {
      return (
        <Button
          className={classes.buttonStyle}
          size="large"
          color={'primary'}
          onClick={() => setShowAstraInfoCollect(true)}>
          Select
        </Button>
      );
    }
    return (
      <Button
        className={classes.buttonStyle}
        size="large"
        color={'primary'}
        onClick={() => (window.location = payoutLink.link)}>
        Select
      </Button>
    );
  }

  function renderLinkBox(payoutLink) {
    return (
      <Paper className={classes.innerSurfaceButton} elevation={3}>
        <div className={classes.iconAndLabel}>
          <Paper className={classes.iconContainer} elevation={3}>
            <img src={payoutLink.icon} alt="" />
          </Paper>
          <div className={classes.payoutLinkBold}>{payoutLink.name}</div>
        </div>
        <div style={{ textAlign: 'right' }}>{renderPayoutLinkButton(payoutLink)}</div>
      </Paper>
    );
  }

  if (!payoutLink) {
    if (errorMessage) {
      return (
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <Paper className={classes.outerSurface} elevation={0}>
              <Paper
                className={classes.innerSurface}
                elevation={3}
                style={{ display: 'flex', fontSize: 13 }}>
                <Paper className={classes.iconContainer} elevation={3}>
                  <img src={warningicon} style={{ width: '20px', margin: 'auto' }} />
                </Paper>
                <div>
                  <span className={classes.boldHeading}>We're sorry!</span> - {errorMessage}
                </div>
              </Paper>
            </Paper>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location = '/tipdirect';
              }}>
              Back to TipDirect
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div style={{ margin: 'auto', marginTop: '50px', width: '40px' }}>
        <CircularProgress />
      </div>
    );
  }

  if (showAstraInfoCollect) {
    return <AstraInfoCollect />;
  }

  if (renderVenmo) {
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <Paper
            className={classes.iconContainer}
            elevation={3}
            style={{
              margin: 'auto',
              marginTop: '100px',
              marginBottom: '22px',
              width: '80px',
              height: '80px',
              borderRadius: '80px'
            }}>
            <img
              src={require('../../assets/images/payout_page/Venmo.svg')}
              style={{ width: '80px' }}
            />
          </Paper>
          <Paper className={classes.outerSurface} elevation={0}>
            <Paper
              className={classes.innerSurface}
              elevation={3}
              style={{ display: 'flex', fontSize: 13 }}>
              <Paper className={classes.iconContainer} elevation={3}>
                <img src={phoneicon} />
              </Paper>
              <div>
                <span className={classes.boldHeading}>Enter your phone number!</span> - Please enter
                the phone number associated with your venmo account.
              </div>
            </Paper>
          </Paper>

          <Paper className={classes.outerSurface} elevation={0}>
            <Paper
              className={classes.innerSurface}
              elevation={3}
              style={{
                display: 'flex',
                fontSize: 13,
                flexDirection: 'column',
                paddingTop: '24px'
              }}>
              <PatternFormat
                customInput={TextField}
                label="Phone Number"
                variant="outlined"
                type="tel"
                placeholder="1234567890"
                format="+1 (###)-###-####"
                allowEmptyFormatting
                onValueChange={values => {
                  setUserPhone(values.value);
                }}
              />
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={submitVenmo}
                disabled={userPhone.toString().length < 10}>
                Submit
              </Button>
            </Paper>
          </Paper>
          <Paper
            className={classes.outerSurface}
            elevation={0}
            style={{ display: errorText.length > 0 ? 'block' : 'none' }}>
            <Paper
              className={classes.innerSurface}
              elevation={3}
              style={{ display: 'flex', fontSize: 13, color: 'red' }}>
              <div>{errorText}</div>
            </Paper>
          </Paper>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '12px',
            maxWidth: '240px',
            margin: 'auto',
            marginTop: '24px'
          }}
          className={classes.payoutLinkBold}>
          TipDirect payouts are made via secure end-to-end encryption.
          <br />
          <img
            style={{ marginTop: '4px' }}
            src={require('../../assets/images/payout_page/Encryption.svg')}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Paper className={classes.outerSurface} elevation={0}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13 }}>
            <Paper className={classes.iconContainer} elevation={3}>
              <img src={claimtip} />
            </Paper>
            <div>
              <span className={classes.boldHeading}>Your tip is ready to be claimed!</span> - Choose
              one of our payout options below. All future payouts will be made using the payout
              method you select.
            </div>
          </Paper>
        </Paper>

        <Paper className={classes.outerSurface} elevation={0}>
          {payoutLinks.map(renderLinkBox)}
        </Paper>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: '600',
          fontSize: '12px',
          maxWidth: '240px',
          margin: 'auto',
          marginTop: '24px'
        }}
        className={classes.payoutLinkBold}>
        TipDirect payouts are made via secure end-to-end encryption.
        <br />
        <img
          style={{ marginTop: '4px' }}
          src={require('../../assets/images/payout_page/Encryption.svg')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(PayoutLink);
