import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/styles';
import { currencySign } from '@utils/helpers';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px 0px 26px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      padding: '0px 20px 0px 20px'
    }
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#000'
  },
  body: {
    marginTop: '34px'
  },
  infoBox: {
    padding: '4px 25px 4px 26px',
    borderRadius: '11px',
    fontWeight: 'bold',
    minWidth: '190px',
    width: '190px',
    maxWidth: '190px',
    textAlign: 'center',
    backgroundColor: 'rgba(216, 216, 216, 0.4)'
  },
  arrow: {
    fontSize: '32px',
    width: '20px',
    marginTop: '-5px',
    color: '#b0b0b0',
    textAlign: 'center',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '40px',
    maxHeight: '40px'
  },
  switcherContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  subheader: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#b0b0b0'
  },
  changeText: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#b0b0b0'
  },
  green: {
    color: '#018101'
  },
  red: {
    color: '#fe424d'
  }
}));

const ALL_STATS = ['UNREMITTED', 'TOTAL_TIPS_TODAY', 'TOTAL_TIPS_THIS_WEEK', 'TOTAL_TIPS_ALL_TIME'];

const TotalTipsInformation = ({ unremittedInformation, tippingMetrics, profileCurrency }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [currentStatIndex, setCurrentStatIndex] = useState(0);

  const previousStat = () => {
    if (currentStatIndex === 0) {
      setCurrentStatIndex(ALL_STATS.length - 1);
      return;
    }

    setCurrentStatIndex(currentStatIndex - 1);
  };

  const nextStat = () => {
    if (currentStatIndex === ALL_STATS.length - 1) {
      setCurrentStatIndex(0);
      return;
    }

    setCurrentStatIndex(currentStatIndex + 1);
  };

  const renderFigures = () => {
    switch (currentStatIndex) {
      case 0:
        return `${currencySign(profileCurrency)}${(
          unremittedInformation.totalUnremittedAmount / 100
        ).toFixed(2)}`;
      case 1:
        return `${currencySign(profileCurrency)}${(tippingMetrics.today / 100).toFixed(2)}`;
      case 2:
        return `${currencySign(profileCurrency)}${(tippingMetrics.lastSevenDays / 100).toFixed(2)}`;
      case 3:
        return `${currencySign(profileCurrency)}${(tippingMetrics.allTime / 100).toFixed(2)}`;
    }
  };

  const renderText = () => {
    switch (currentStatIndex) {
      case 0:
        return intl.formatMessage(messages.unclaimedTips);
      case 1:
        return intl.formatMessage(messages.today);
      case 2:
        return intl.formatMessage(messages.recentPayments);
      case 3:
        return intl.formatMessage(messages.allTime);
    }
  };

  const renderFooter = () => {
    switch (currentStatIndex) {
      case 0:
        return '';
      case 1:
        return (
          <>
            <div className={classes.subheader}>{format(new Date(Date.now()), 'dd MMM yy')}</div>
            <div className={classes.changeText} />
          </>
        );
      case 2:
        return (
          <>
            <div className={classes.subheader}>{intl.formatMessage(messages.lastSevenDays)}</div>
            <div
              className={`${classes.changeText} ${
                tippingMetrics.sevenDayPercentageDiff[0] === '+'
                  ? classes.green
                  : tippingMetrics.sevenDayPercentageDiff[0] === '-' &&
                    tippingMetrics.sevenDayPercentageDiff.lenght > 1
                  ? classes.red
                  : ''
              }`}>
              {tippingMetrics.sevenDayPercentageDiff}
            </div>
          </>
        );
      case 3:
        return '';
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.topBar}>
          <div className={classes.headerText}>{renderFigures()}</div>
        </div>
        <div className={classes.switcherContainer}>
          <div className={`${classes.leftArrow} ${classes.arrow}`} onClick={previousStat}>
            &#8249;
          </div>
          <div className={classes.infoBox}>{renderText()}</div>
          <div className={`${classes.rightArrow} ${classes.arrow}`} onClick={nextStat}>
            &#8250;
          </div>
        </div>
        <div className={classes.footer}>{renderFooter()}</div>
      </div>
    </>
  );
};

export default TotalTipsInformation;
