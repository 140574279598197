/*
 * SignUpForm Messages
 *
 * This contains all the text for the SignUpForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SignUpForm';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the SignUpForm component!'
  },
  dontHaveAccount: {
    id: `${scope}.dontHaveAccount`,
    defaultMessage: "Don't have an account yet?"
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: ' Sign up'
  }
});
