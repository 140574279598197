import { defineMessages } from 'react-intl';

export const scope = 'app.components.SignUpForm';

export default defineMessages({
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'For an Improved Experience'
  },
  subHeading: {
    id: `${scope}.subHeading`,
    defaultMessage: 'Download the TipDirect App'
  },
  iOSApp: {
    id: `${scope}.iOSApp`,
    defaultMessage: 'iOS App'
  },
  androidApp: {
    id: `${scope}.androidApp`,
    defaultMessage: 'Android App'
  }
});
