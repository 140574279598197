import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AgentProfileSummaryButton';

export default defineMessages({
  addToursToProfile: {
    id: `${scope}.add_tour_to_profile`,
    defaultMessage: 'Add Tours to Profile'
  },
  copyTipLink: {
    id: `${scope}.copy_tip_link`,
    defaultMessage: 'Copy Tip Link'
  },
  downloadQRCode: {
    id: `${scope}.download_qr_code`,
    defaultMessage: 'Download QR Code'
  },
  previewTippingFlow: {
    id: `${scope}.preview_tipping_flow`,
    defaultMessage: 'Preview Tipping Flow'
  }
});
