import { defineMessages } from 'react-intl';

const scope = 'app.components.TipDirect.PaymentLinkQRGenerator';

export default defineMessages({
  paymentLink: {
    id: `${scope}.paymentLink`,
    defaultMessage: 'Payment Link'
  },
  tagAndAmount: {
    id: `${scope}.tagAndAmount`,
    defaultMessage: 'Tag & Amount'
  },
  tagRequired: {
    id: `${scope}.tagRequired`,
    defaultMessage: 'Tag'
  },
  shareLink: {
    id: `${scope}.shareLink`,
    defaultMessage: 'Share Link'
  }
});
