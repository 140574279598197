export const StatusType = {
  pending: 'Pending',
  active: 'Active',
  expired: 'Expired'
};

export const StatusBackgroundColor = {
  pending: '#555555',
  active: '#d9ffd7',
  expired: '#ffccd8'
};

export const StatusForeColor = {
  pending: '#eaeaea',
  active: '#12573e',
  expired: '#fe424d'
};

export const UserType = {
  invite: 'Invite',
  user: 'Guide',
  admin: 'Admin'
};
