import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  agentsProfiles: [],
  tours: [],
  error: null,
  isLoading: false
};

const fetchAgentsProfiles = state => ({
  ...state,
  isLoading: true
});

const fetchAgentsProfilesSuccess = (state, agentsProfiles) => ({
  ...state,
  agentsProfiles,
  isLoading: false,
  error: null
});

const fetchToursInit = state => ({
  ...state,
  isLoading: true
});

const fetchToursSuccess = (state, tours) => {
  return {
    ...state,
    error: null,
    isLoading: false,
    tours
  };
};

const facedError = (state, error) => ({
  ...state,
  error
});

export default createReducer(
  {
    [actionTypes.FETCH_AGENTS_PROFILES]: fetchAgentsProfiles,
    [actionTypes.FETCH_AGENTS_PROFILES_SUCCESS]: fetchAgentsProfilesSuccess,
    [actionTypes.FETCH_TOURS]: fetchToursInit,
    [actionTypes.FETCH_TOURS_SUCCESS]: fetchToursSuccess,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
