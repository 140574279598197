import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.AccessControlledButton';

export default defineMessages({
  proPlanTooltip: {
    id: `${scope}.proPlanTooltip`,
    defaultMessage:
      'Upgrade your subscription to the Pro or Pro Plus plans to start using this feature.'
  },
  pro: {
    id: `${scope}.pro`,
    defaultMessage: 'Pro'
  }
});
