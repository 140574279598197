import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, TextField, CircularProgress } from '@material-ui/core';
import claimtip from '../../../assets/images/payout_page/Transfer.svg';
import faceIcon from '../../../assets/images/payout_page/Details.png';
import phoneicon from '../../../assets/images/payout_page/Phone.png';
import warningicon from '../../../assets/images/warning.svg';
import PoweredBy from '../../../components/PoweredBy';
import { loginPayPalAccount } from '../../../api/paypal';
import { createUserIntent } from '../../../api/astra';
import {
  getPayoutLink,
  createVenmoAccount,
  RemitVenmoPaymentIntentIfAble
} from '../../../api/payout';
import { PatternFormat } from 'react-number-format';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  container: {},
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto'
  },
  paymentMethods: {
    border: '1px solid red'
  },
  outerSurface: {
    backgroundColor: '#f9f9f9',
    padding: 22,
    margin: 11,
    borderRadius: 16
  },
  innerSurface: {
    backgroundColor: 'white',
    padding: 12,
    paddingLeft: 12,
    borderRadius: 16
  },
  innerSurfaceButton: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    padding: 12,
    borderRadius: 16,
    marginBottom: '16px'
  },
  boldHeading: {
    color: 'black',
    fontWeight: 'bold'
  },
  imageStyle: {
    margin: 22
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonStyle: {
    padding: 8
  },
  iconImage: {
    width: '35px',
    height: '35px'
  },
  iconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    borderRadius: 35,
    marginRight: 12,
    width: '35px',
    height: '35px'
  },
  payoutLinkBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.22px',
    color: '#000'
  },
  iconAndLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    margin: '12px'
  }
}));

export default function AstraInfoCollect() {
  const classes = useStyles();
  let [userPhone, setUserPhone] = useState(false);
  let [errorText, setErrorText] = useState('');

  const [postalCodeFormat, setPostalCodeFormat] = useState('#####');

  let [buttonEnabled, setButtonEnabled] = useState(false);

  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [city, setCity] = useState('');
  let [state, setState] = useState('');
  let [postalCode, setPostalCode] = useState('');
  let [dateOfBirth, setDateOfBirth] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    // Update the document title using the browser API
    if (!formHasErrors() && !buttonEnabled) {
      setButtonEnabled(true);
    }
    if (buttonEnabled && formHasErrors()) {
      setButtonEnabled(false);
    }
  });

  async function submit() {
    let formattedDate =
      dateOfBirth.substring(0, 4) +
      '-' +
      dateOfBirth.substring(4, 6) +
      '-' +
      dateOfBirth.substring(6, 8);
    let createUserIntentBody = {
      address1: address1,
      address2: address2,
      city: city,
      state: state.toUpperCase(),
      postal_code: postalCode,
      date_of_birth: formattedDate,
      phone: '+1' + phoneNumber
    };
    let resp = await createUserIntent(createUserIntentBody);
    if (resp.authorisationUrl) {
      window.location = resp.authorisationUrl;
    }
  }

  function isValidUSZip(sZip) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(sZip);
  }

  function isValidDate(dateString) {
    if (dateString.length != 8) {
      return false;
    }
    let newStr =
      dateString.substring(0, 4) +
      '/' +
      dateString.substring(4, 6) +
      '/' +
      dateString.substring(6, 8);
    return !isNaN(new Date(newStr));
  }

  function isEmpty(str) {
    return !str || str.length === 0;
  }
  function formHasErrors() {
    let valuesToCheck = [address1, city, state, postalCode, dateOfBirth, phoneNumber];
    for (let i = 0; i < valuesToCheck.length; i++) {
      const element = valuesToCheck[i];
      if (isEmpty(element)) {
        return true;
      }
    }
    if (phoneNumber.length < 10) {
      return true;
    }

    if (!isValidDate(dateOfBirth)) {
      return true;
    }
    if (!isValidUSZip(postalCode)) {
      console.log('p code invalid', postalCode);
      return true;
    }
    return false;
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Paper
          className={classes.iconContainer}
          elevation={3}
          style={{
            margin: 'auto',
            marginTop: '100px',
            marginBottom: '22px',
            width: '80px',
            height: '80px',
            borderRadius: '80px',
            overflow: 'hidden'
          }}>
          <img src={faceIcon} style={{ width: '60px', height: '60px', margin: 'auto' }} />
        </Paper>
        <Paper className={classes.outerSurface} elevation={0}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13 }}>
            <Paper className={classes.iconContainer} elevation={3}>
              <img src={claimtip} />
            </Paper>
            <div>
              <span className={classes.boldHeading}>Enter your details!</span> - Lets collect some
              additional info to process your card payouts
            </div>
          </Paper>
        </Paper>

        <Paper className={classes.outerSurface} elevation={0}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{
              display: 'flex',
              fontSize: 13,
              flexDirection: 'column',
              paddingTop: '24px'
            }}>
            <TextField
              className={classes.textField}
              variant="outlined"
              label={'Address'}
              InputLabelProps={{ shrink: true }}
              value={address1}
              onChange={e => {
                setAddress1(e.target.value);
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              label={'Address 2'}
              InputLabelProps={{ shrink: true }}
              value={address2}
              onChange={e => {
                setAddress2(e.target.value);
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              label={'City'}
              InputLabelProps={{ shrink: true }}
              value={city}
              onChange={e => {
                setCity(e.target.value);
              }}
            />
            <TextField
              className={classes.textField}
              variant="outlined"
              inputProps={{ maxLength: 2 }}
              label={'State (AZ, FL, NY)'}
              InputLabelProps={{ shrink: true }}
              value={state}
              onChange={e => {
                setState(e.target.value);
              }}
            />

            <TextField
              customInput={TextField}
              className={classes.textField}
              label="Postal Code (10101 OR 10101-1010)"
              variant="outlined"
              type="Postal Code"
              InputLabelProps={{ shrink: true }}
              value={postalCode}
              onChange={e => {
                setPostalCode(e.target.value);
              }}
            />
            <PatternFormat
              customInput={TextField}
              className={classes.textField}
              label="Date of Birth (YYYY-MM-DD)"
              variant="outlined"
              type="dob"
              placeholder="19880113"
              format="####-##-##"
              allowEmptyFormatting
              onValueChange={values => {
                setDateOfBirth(values.value);
              }}
            />

            <PatternFormat
              customInput={TextField}
              className={classes.textField}
              label="Phone Number"
              variant="outlined"
              type="tel"
              placeholder="1234567890"
              format="+1 (###)-###-####"
              allowEmptyFormatting
              onValueChange={values => {
                setPhoneNumber(values.value);
              }}
            />
            <br />

            <Button
              variant="contained"
              color="primary"
              disabled={!buttonEnabled}
              onClick={() => {
                submit();
              }}>
              Submit
            </Button>
          </Paper>
        </Paper>
        <Paper
          className={classes.outerSurface}
          elevation={0}
          style={{ display: errorText.length > 0 ? 'block' : 'none' }}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13, color: 'red' }}>
            <div>{errorText}</div>
          </Paper>
        </Paper>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: '600',
          fontSize: '12px',
          maxWidth: '240px',
          margin: 'auto',
          marginTop: '24px'
        }}
        className={classes.payoutLinkBold}>
        TipDirect payouts are made via secure end-to-end encryption.
        <br />
        <img
          style={{ marginTop: '4px' }}
          src={require('../../../assets/images/payout_page/Encryption.svg')}
        />
      </div>
    </div>
  );
}
