import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  profile: null,
  error: null,
  isLoading: false,
  connectStripeResponse: null,
  activeTours: [],
  paypalLoginUrl: null,
  paypalAccount: null,
  isUpdatingProfile: false
};
const fetchUserProfile = (state, profile) => ({
  ...state,
  error: null,
  profile,
  isLoading: false
});

const fetchUserProfileInit = state => ({
  ...state,
  error: null,
  isLoading: true
});

const updateProfileInit = state => ({
  ...state,
  isUpdatingProfile: true
});

const updateProfileSuccess = (state, profile) => ({
  ...state,
  profile,
  isUpdatingProfile: false
});

const uploadUserProfileImageSuccess = (state, url) => ({
  ...state,
  profile: {
    ...state.profile,
    avatarUrl: url
  }
});

const connectStripeSuccess = (state, connectStripeResponse) => ({
  ...state,
  connectStripeResponse
});

const fetchActiveToursSuccess = (state, activeTours) => ({
  ...state,
  activeTours
});

const getPayPalLoginUrlSuccess = (state, paypalLoginUrl) => ({
  ...state,
  paypalLoginUrl
});

const paypalLoginCallbackSuccess = (state, paypalAccount) => ({
  ...state,
  paypalAccount
});

const facedError = (state, error) => ({
  ...state,
  error
});

const fetchAgentsProfilesSuccess = (state, agentsProfiles) => ({
  ...state,
  agentsProfiles
});

export default createReducer(
  {
    [actionTypes.FETCH_AGENTS_PROFILES_SUCCESS]: fetchAgentsProfilesSuccess,
    [actionTypes.FETCH_PROFILE]: fetchUserProfileInit,
    [actionTypes.FETCH_PROFILE_SUCCESS]: fetchUserProfile,
    [actionTypes.UPLOAD_USER_PROFILE_IMAGE_SUCCESS]: uploadUserProfileImageSuccess,
    [actionTypes.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
    [actionTypes.UPDATE_PROFILE]: updateProfileInit,
    [actionTypes.CONNECT_STRIPE_SUCCESS]: connectStripeSuccess,
    [actionTypes.FETCH_ACTIVE_TOURS_SUCCESS]: fetchActiveToursSuccess,
    [actionTypes.GET_PAYPAL_LOGIN_URL_SUCCESS]: getPayPalLoginUrlSuccess,
    [actionTypes.PAYPAL_LOGIN_CALLBACK_SUCCESS]: paypalLoginCallbackSuccess,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
