import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.GroupDetails';

export default defineMessages({
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Members'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  team: {
    id: `${scope}.team`,
    defaultMessage: 'Team'
  },
  subText: {
    id: `${scope}.subText`,
    defaultMessage: 'Currently in this team'
  },
  leaveTeam: {
    id: `${scope}.leaveTeam`,
    defaultMessage: 'Leave Team'
  },
  joinTeam: {
    id: `${scope}.joinTeam`,
    defaultMessage: 'Join Team'
  },
  removeFromTeam: {
    id: `${scope}.removeFromTeam`,
    defaultMessage: 'Remove from Team'
  },
  claimResource: {
    id: `${scope}.claimResource`,
    defaultMessage: 'Claim Resource'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  leaveTeamConfirmation: {
    id: `${scope}.leaveTeamConfirmation`,
    defaultMessage: 'Are you sure you wish to leave this team?'
  },
  claimResourceConfirmation: {
    id: `${scope}.claimResourceConfirmation`,
    defaultMessage:
      'Are you sure you wish to proceed? Claiming this resource will create a new team. This action cannot be undone'
  }
});
