import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Groups.TeamSettings';

export default defineMessages({
  splitYourTips: {
    id: `${scope}.splitYourTips`,
    defaultMessage: 'Split your tips'
  },
  setYourSplitPercentage: {
    id: `${scope}.setYourSplitPercentage`,
    defaultMessage: 'Set your split percentage'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  infoBoxTextEqual: {
    id: `${scope}.infoBoxTextEqual`,
    defaultMessage:
      'Tips will be split evenly by default. Any changes to the split of tips here will be communicated to everyone in the team'
  },
  infoBoxTextNotEqual: {
    id: `${scope}.infoBoxTextNotEqual`,
    defaultMessage:
      'Tips will be split based on the percentages below. Any changes to the split of tips here will be communicated to everyone in the team'
  }
});
