import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.TotalTipsInformation';

export default defineMessages({
  starRatings: {
    id: `${scope}.starRatings`,
    defaultMessage: 'Star Ratings'
  },
  totalStars: {
    id: `${scope}.totalStars`,
    defaultMessage: 'Total Stars'
  },
  today: {
    id: `${scope}.today`,
    defaultMessage: 'Today'
  },
  recentPayments: {
    id: `${scope}.recentPayments`,
    defaultMessage: 'Recent Payments'
  },
  allTime: {
    id: `${scope}.allTime`,
    defaultMessage: 'All Time'
  },
  lastSevenDays: {
    id: `${scope}.lastSevenDays`,
    defaultMessage: 'Last 7 days'
  },
  unclaimedTips: {
    id: `${scope}.unclaimedTips`,
    defaultMessage: 'Unclaimed Tips'
  }
});
