import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const fetchAgentsProfiles = createAction(actionTypes.FETCH_AGENTS_PROFILES);
export const fetchAgentsProfilesSuccess = createAction(actionTypes.FETCH_AGENTS_PROFILES_SUCCESS);

export const fetchReviewsStatistic = createAction(actionTypes.FETCH_REVIEWS_STATISTIC);
export const fetchReviewsStatisticSuccess = createAction(
  actionTypes.FETCH_REVIEWS_STATISTIC_SUCCESS
);
