import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  addToursToProfile,
  fetchActiveTours,
  fetchAgentsProfiles,
  revokeInvite,
  sendInvitesForGuides
} from '../../../api/taptotip';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* fetchAgentsProfilesWorker() {
  try {
    const agentsProfiles = yield call(fetchAgentsProfiles);

    yield put(actions.fetchAgentsProfilesSuccess(agentsProfiles));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchSendInvitesWorker({ payload }) {
  try {
    yield call(sendInvitesForGuides, payload);
    yield call(fetchAgentsProfilesWorker);
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* revokeInviteWorker({ payload }) {
  try {
    yield call(revokeInvite, payload);
    yield call(fetchAgentsProfilesWorker);
    yield put(setSnackbarStatus(snackbarStatus.inviteDeleted));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchActiveToursWorker() {
  try {
    const activeTours = yield call(fetchActiveTours);

    yield put(actions.fetchActiveToursSuccess(activeTours));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* addToursToProfileWorker({ payload: { userId, tourIds } }) {
  try {
    yield call(addToursToProfile, userId, tourIds);

    yield put(actions.fetchAgentsProfiles());
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_AGENTS_PROFILES, fetchAgentsProfilesWorker);
  yield takeLatest(types.SEND_GUIDE_INVITES, fetchSendInvitesWorker);
  yield takeLatest(types.FETCH_ACTIVE_TOURS, fetchActiveToursWorker);
  yield takeLatest(types.REVOKE_INVITE, revokeInviteWorker);
  yield takeLatest(types.ADD_TOURS_TO_PROFILE, addToursToProfileWorker);
}
