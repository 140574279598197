import ResourcesFTU_Icon from '@assets/images/ResourcesFTU_Icon.svg';
import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import AccessControlledButton from '../../TapToTip/AccessControlledButton';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '80vh',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      flexDirection: 'row'
    }
  },
  blankSlateText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    padding: '24px',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      maxWidth: '400px',
      height: 'fit-content'
    }
  },
  icon: {
    height: '102px',
    width: '102px',
    borderRadius: '50%'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000',
    marginBottom: '5px'
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#000',
    marginBottom: '5px'
  },
  text: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#6a6868',
    width: '100%'
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      justifyContent: 'flex-end'
    }
  },
  createButton: {
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      width: 'fit-content'
    }
  },
  buttonText: {
    marginRight: '8px'
  }
}));

const ResourcesBlankSlate = ({ openCreateResources }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.blankSlateText}>
          <img src={ResourcesFTU_Icon} className={classes.icon} alt="Resources blank slate icon" />
          <div className={classes.title}>{intl.formatMessage(messages.title)}</div>
          <div className={classes.subTitle}>{intl.formatMessage(messages.subTitle)}</div>
          <div className={classes.text}>{intl.formatMessage(messages.text)}</div>
        </div>
        {openCreateResources && (
          <div className={classes.bottomButtons}>
            <AccessControlledButton
              elementStyle={classes.createButton}
              feature={'ResourceTippingPoints'}
              tooltipTitle={''}
              title={intl.formatMessage(messages.createResource)}
              onClick={openCreateResources}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ResourcesBlankSlate;
