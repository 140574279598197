import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Emoji from 'react-emoji-render';

const useStyles = makeStyles(() => ({
  avatar: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    cursor: 'default'
  },
  icon: {
    '& > span': {
      fontSize: '28px'
    }
  },
  selected: {
    border: 'solid blue 2px'
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(118, 119, 119, 0.04)'
    }
  }
}));

const EmojiAvatar = ({ code, onClick, selected, isClickable }) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`${classes.avatar} ${selected ? classes.selected : null} ${isClickable &&
        classes.clickable} `}
      onClick={onClick}>
      <Emoji text={code} className={classes.icon} />
    </div>
  );
};

export default EmojiAvatar;
