import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  isAssigned: false,
  users: [],
  resources: []
};

const assignCardToUserSuccess = state => ({
  ...state,
  isAssigned: true
});

const fetchUsersSuccess = (state, users) => ({
  ...state,
  users
});

const fetchResourcesSuccess = (state, resources) => ({
  ...state,
  resources
});

export default createReducer(
  {
    [actionTypes.ASSIGN_CARD_TO_USER_SUCCESS]: assignCardToUserSuccess,
    [actionTypes.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
    [actionTypes.FETCH_TIP_DIRECT_RESOURCES_SUCCESS]: fetchResourcesSuccess
  },
  initialState
);
