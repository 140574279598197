import { makeStyles } from '@material-ui/core/styles';
import { StyleBreakpoints, UserRoles } from '@utils/constants';
import React from 'react';
import { useIntl } from 'react-intl';
import DateRangePicker from '../../../../components/Common/DateRangePicker/DateRangePicker';
import MultiplePicker from '../../../../components/Common/MultiplePicker/MultiplePicker';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      padding: '0 24px'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%'
  },
  filterRow: {
    marginBottom: theme.spacing(1)
  },
  dateRangeBar: {
    flex: 1,
    minWidth: '250px',
    padding: '8px 0px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  timeRangeTitle: {
    fontWeight: '500',
    marginRight: '15px'
  },
  multipleSelectBar: {
    flex: 1,
    minWidth: '250px',
    maxWidth: '250px',
    padding: '8px 0px',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      maxWidth: '400px'
    }
  },
  filterItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      flexDirection: 'column'
    }
  }
}));

export function Filters({
  tours,
  profiles,
  selectedTours,
  setSelectedTours,
  selectedProfiles,
  setSelectedProfiles,
  dateRange,
  setDateRange,
  userRole
}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.row} ${classes.filterRow}`}>
        <div className={classes.filterItems}>
          <div className={classes.multipleSelectBar}>
            <MultiplePicker
              items={tours}
              selectedItems={selectedTours}
              setSelectedItems={setSelectedTours}
              defaultOptionLabel={intl.formatMessage(messages.allTours)}
            />
          </div>
          {userRole === UserRoles.admin && (
            <div className={classes.multipleSelectBar}>
              <MultiplePicker
                items={profiles}
                getTitle={item => `${item.firstName} ${item.lastName}`}
                getValue={item => item.userId}
                selectedItems={selectedProfiles}
                setSelectedItems={setSelectedProfiles}
                defaultOptionLabel={intl.formatMessage(messages.allProfiles)}
              />
            </div>
          )}
          <div className={classes.dateRangeBar}>
            <span className={classes.timeRangeTitle}>{intl.formatMessage(messages.dateRange)}</span>
            <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
