import { Analytics } from '@analytics';
import { Button, Chip, InputAdornment, Snackbar, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterNone as CopyIcon, InfoOutlined } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useCSVReader } from 'react-papaparse';
import { useHistory } from 'react-router';
import DashboardShare from '../../../assets/images/DashboardShare.png';
import { StyleBreakpoints } from '../../../utils/constants';
import CustomizableModal from '../../Common/CustomizableModal';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  modalBody: {
    width: '80vw',
    maxWidth: '600px'
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    }
  },
  title: {
    fontSize: '18px',
    color: '#555555',
    margin: '20px 0 0'
  },
  desc: {
    margin: '5px 0 30px'
  },
  adornment: {
    flexWrap: 'wrap',
    gap: '5px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: ' 0 1 auto',
    maxHeight: 'unset',
    height: 'auto',
    marginBottom: '14px'
  },
  error: {
    color: 'red'
  },
  companyName: {
    fontSize: '18px',
    padding: '0 0 2px 2px'
  },
  setNameButton: {
    order: -1,
    position: 'absolute',
    left: 0,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginRight: 'auto',
      position: 'static'
    }
  },
  zone: {
    width: '100%',
    border: 'dashed',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    borderColor: '#C8C8C8',
    backgroundColor: '#F0F0F0',
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '20px'
  },
  zoneHover: {
    borderColor: '#686868'
  },
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  copyIcon: {
    alignSelf: 'center',
    marginLeft: '4px',
    cursor: 'pointer',
    float: 'right',
    width: '17px',
    height: '17px',

    '&:hover': {
      color: 'black'
    }
  },
  inviteInfo: {
    width: 'fit-content'
  },
  sendInviteBlock: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  inviteLinkTitle: {
    fontSize: '16px',
    color: '#555555',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  infoIcon: {
    color: '#55555591',
    width: '20px'
  },
  inviteTooltip: {
    backgroundColor: '#555555',
    padding: '16px'
  },
  inviteButtonBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginBottom: '20px'
  }
}));

const InviteGuideModal = ({
  isOpen,
  setIsOpen,
  sendInvitesToGuides,
  companyName,
  isRecommendationEmail = false,
  inviteCode
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { CSVReader } = useCSVReader();
  const openInviteLink = `${window.location.origin}/signupfrominvite/${inviteCode}`;

  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [zoneHover, setZoneHover] = useState(false);
  const [showAlert, setShouldShowCopyAlert] = useState(false);

  function handleClose() {
    setIsOpen(false);
    setEmails([]);
    setValue('');
    setError(null);
  }

  useEffect(() => {
    if (isOpen) {
      Analytics.trackViewFeature('InviteGuidesByEmail');
      Analytics.trackViewFeature('CompanyInviteLink');
    }
  }, [isOpen]);

  const handleQRDownload = async () => {
    Analytics.trackUseFeature('CompanyInviteLink');
    const data = await QRCode.toDataURL(openInviteLink).catch(err => {
      console.error(err);
    });

    const a = document.createElement('a');
    a.download = `Tip-Direct-Open-Invite_${companyName}.png`;
    a.href = data;
    a.click();
  };

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', 'Space'].includes(evt.key)) {
      evt.preventDefault();

      validateEmails();
    }
  };

  const validateEmails = () => {
    if (value.trim() && isValid(value.trim())) {
      setEmails([...emails, value.trim()]);
      setValue('');
    }
  };

  const handleChange = evt => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = item => {
    setEmails(emails.filter(i => i !== item));
  };

  const handlePaste = evt => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const newEmails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (newEmails) {
      const toBeAdded = newEmails.filter(newEmail => !isInList(newEmail));
      setEmails([...emails, ...toBeAdded]);
    }
  };

  const isValid = email => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);
      return false;
    }

    return true;
  };

  const isInList = email => {
    return emails.includes(email);
  };

  const isEmail = email => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  const handleSetNameClick = () => {
    history.push(`/settings/account/`);
  };

  const isCompanyNameValid = companyName?.length > 2;

  const companyNameOrDefaultString = isCompanyNameValid
    ? companyName
    : intl.formatMessage(messages.nameNotSet);

  const addEmails = data => {
    let emailIndex;
    let isolatedEmails = [];

    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (isEmail(data[i][j])) {
          emailIndex = j;
          break;
        }
      }
    }

    if (emailIndex > -1) {
      data.map(row => {
        isEmail(row[emailIndex]) && isolatedEmails.push(row[emailIndex]);
      });
      error && setError(null);
    } else {
      setError('Valid email address not present in first two lines of file');
    }

    isolatedEmails.length > 0 && setEmails([...emails, ...isolatedEmails]);
  };

  const getModalBody = () => {
    return (
      <div className={classes.modalBody}>
        <div className={classes.topBlock}>
          <div>
            <h3 className={classes.title}>
              {isRecommendationEmail ? (
                intl.formatMessage(messages.recommendTitle)
              ) : (
                <>
                  {intl.formatMessage(messages.inviteGuide)}:{' '}
                  <Button
                    color="primary"
                    className={classes.companyName}
                    onClick={isCompanyNameValid ? null : handleSetNameClick}>
                    {companyNameOrDefaultString}
                  </Button>
                </>
              )}
            </h3>
            <p className={classes.desc}>
              {intl.formatMessage(
                isRecommendationEmail ? messages.recommendEnterAnEmail : messages.enterAnEmail
              )}
            </p>
          </div>
        </div>
        <TextField
          multiline
          className={classes.input}
          label={intl.formatMessage(messages.emailAddress)}
          placeholder={intl.formatMessage(messages.placeholder)}
          variant="outlined"
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={validateEmails}
          onPaste={handlePaste}
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.adornment}>
                {emails.map(item => (
                  <Chip key={item} label={item} onDelete={() => handleDelete(item)} />
                ))}
              </InputAdornment>
            )
          }}
        />
        <CSVReader
          onUploadAccepted={results => {
            addEmails(results.data);
            setZoneHover(false);
          }}
          onDragOver={event => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={event => {
            event.preventDefault();
            setZoneHover(false);
          }}>
          {({ getRootProps }) => (
            <>
              <div
                {...getRootProps()}
                className={`${classes.zone} ${zoneHover && classes.zoneHover}`}>
                {intl.formatMessage(messages.dragAndDrop)}
              </div>
            </>
          )}
        </CSVReader>
        {error ? <p className={classes.error}>{error}</p> : <p />}
        <div className={classes.sendInviteBlock}>
          <Button
            variant="contained"
            color="primary"
            disabled={!emails.length}
            onClick={() => {
              sendInvitesToGuides({ emails, isResend: false, isRecommendationEmail });
              Analytics.trackUseFeature('InviteGuides');
              Analytics.track(
                isRecommendationEmail ? 'recommend to a friend clicked' : 'invite guides clicked',
                { number_of_emails: emails.length }
              );
              handleClose();
            }}>
            {intl.formatMessage(messages.sendInvite)}
          </Button>
        </div>

        {!isRecommendationEmail && (
          <div>
            <Tooltip
              className={classes.inviteInfo}
              title={
                <div>
                  <img
                    src={DashboardShare}
                    alt="Preview"
                    width={'100%'}
                    style={{ borderRadius: '8px' }}
                  />
                  <p>{intl.formatMessage(messages.tooltipTitle)}</p>
                </div>
              }
              classes={{
                tooltip: classes.inviteTooltip
              }}
              placement="top">
              <h3 className={classes.inviteLinkTitle}>
                {intl.formatMessage(messages.companyInviteLink)}
                <InfoOutlined className={classes.infoIcon} />
              </h3>
            </Tooltip>
            <div className={classes.inviteButtonBlock}>
              <CopyToClipboard text={openInviteLink} onCopy={() => setShouldShowCopyAlert(true)}>
                <Button color="primary" variant="contained" fullWidth>
                  <div>{intl.formatMessage(messages.copyLink)}</div>
                  <CopyIcon className={classes.copyIcon} />
                </Button>
              </CopyToClipboard>
              <Button color="primary" variant="contained" fullWidth onClick={handleQRDownload}>
                <div>{intl.formatMessage(messages.downloadQRCode)}</div>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getCustomButtons = () => {
    return isCompanyNameValid
      ? []
      : [
          <Button
            key="1"
            className={classes.setNameButton}
            onClick={handleSetNameClick}
            color="primary">
            {intl.formatMessage(messages.setName)}
          </Button>
        ];
  };

  return (
    <>
      <CustomizableModal
        isOpen={isOpen}
        onClose={handleClose}
        withHeader={false}
        withFooter={false}
        body={getModalBody()}
        customButtons={getCustomButtons()}
      />
      <Snackbar
        open={showAlert}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="info"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.inviteLinkCopied)}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default InviteGuideModal;
