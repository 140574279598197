import { call, put, takeLatest } from '@redux-saga/core/effects';
import { date2str } from '@utils/helpers';
import { fetchAgentsProfiles, fetchReviewsStatistic } from '../../../api/taptotip';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function normalizeDateStrings(payload) {
  return {
    ...payload,
    dateRange: {
      start: date2str(payload.dateRange.start),
      end: date2str(payload.dateRange.end)
    }
  };
}

function* fetchAgentsProfilesWorker() {
  try {
    const agentsProfiles = yield call(fetchAgentsProfiles);

    yield put(actions.fetchAgentsProfilesSuccess(agentsProfiles));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchReviewsStatisticWorker({ payload }) {
  try {
    const result = yield call(fetchReviewsStatistic, normalizeDateStrings(payload));
    yield put(actions.fetchReviewsStatisticSuccess(result));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_AGENTS_PROFILES, fetchAgentsProfilesWorker);
  yield takeLatest(types.FETCH_REVIEWS_STATISTIC, fetchReviewsStatisticWorker);
}
