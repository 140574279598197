/*
 * Payments Messages
 *
 * This contains all the text for the Payments container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PayoutLink.AstraConfirm';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: "Your payment is on it's way!"
  },

  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage: 'Payment method setup'
  },

  bodyText: {
    id: `${scope}.bodyText`,
    defaultMessage: ' - We will make all future payments to you via this payment method.'
  }
});
