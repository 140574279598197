import { defineMessages } from 'react-intl';

const scope = 'app.components.TipDirect.Profile';

export default defineMessages({
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile'
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: 'Your profile information'
  },
  firstNameHeader: {
    id: `${scope}.profile.name.header`,
    defaultMessage: 'First Name'
  },
  firstNameLabel: {
    id: `${scope}.profile.name.label`,
    defaultMessage: 'First Name'
  },
  lastNameHeader: {
    id: `${scope}.lastNameHeader`,
    defaultMessage: 'Last Name'
  },
  lastNameLabel: {
    id: `${scope}.lastNameLabel`,
    defaultMessage: 'Last Name'
  },
  nicknameHeader: {
    id: `${scope}.nicknameHeader`,
    defaultMessage: 'Nickname'
  },
  nicknameLabel: {
    id: `${scope}.nicknameLabel`,
    defaultMessage: 'Nickname'
  },
  nicknameToolTip: {
    id: `${scope}.nicknameToolTip`,
    defaultMessage:
      'Nicknames will be only shown to customers. Your first and last name will be used by the system.'
  },
  imageHeader: {
    id: `${scope}.profile.image.header`,
    defaultMessage: 'Profile Image'
  },
  imageButton: {
    id: `${scope}.profile.image.button`,
    defaultMessage: 'Add Profile Photo'
  },
  imageSubmit: {
    id: `${scope}.profile.image.submit`,
    defaultMessage: 'Submit'
  },
  imageCancel: {
    id: `${scope}.profile.image.cancel`,
    defaultMessage: 'Cancel'
  },
  paymentHeader: {
    id: `${scope}.profile.payment.header`,
    defaultMessage: 'Payment Currency'
  },
  paymentLabel: {
    id: `${scope}.profile.payment.label`,
    defaultMessage: 'Default'
  },
  bioHeader: {
    id: `${scope}.profile.bio.header`,
    defaultMessage: 'Profile Bio'
  },
  bioLabel: {
    id: `${scope}.profile.bio.label`,
    defaultMessage: 'Bio'
  },
  bioPlaceholder: {
    id: `${scope}.profile.bio.placeholder`,
    defaultMessage: 'Let customers know that all gratuity is greatly appreciated.'
  },
  defaultPaymentAmountHeader: {
    id: `${scope}.profile.defaultPaymentAmount.header`,
    defaultMessage: 'Default Payment Amount'
  },
  defaultPaymentAmountLabel: {
    id: `${scope}.profile.defaultPaymentAmount.label`,
    defaultMessage: 'Default Payment Amount'
  },
  countryHeader: {
    id: `${scope}.profile.country.header`,
    defaultMessage: 'Country'
  },
  countryLabel: {
    id: `${scope}.profile.country.label`,
    defaultMessage: 'Country'
  },
  connecting: {
    id: `${scope}.connecting`,
    defaultMessage: 'Connecting to Stripe'
  },
  stripeConnectDescription: {
    id: `${scope}.stripe_connect_description`,
    defaultMessage: 'Integrate the online payment gateway to begin taking payments. '
  },
  getStarted: {
    id: `${scope}.get_started`,
    defaultMessage: 'Get Started'
  },
  paymentIntegrationStatus: {
    id: `${scope}.payment_integration_status`,
    defaultMessage: 'Payment Integration Status'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active'
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Inactive'
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save'
  },
  stripeConnectMessaging: {
    id: `${scope}.stripe_connect_messaging`,
    defaultMessage:
      'Profiles not using a Stripe connect account will have slower payout times and an additional banking fee.'
  },
  stripeConnect: {
    id: `${scope}.stripeConnectDescription`,
    defaultMessage: 'Stripe Connect'
  },
  businessInformation: {
    id: `${scope}.businessInformation`,
    defaultMessage: 'Business Information'
  },
  yourTaxInfo: {
    id: `${scope}.yourTaxInfo`,
    defaultMessage: 'Your tax information'
  },
  stripeAccount: {
    id: `${scope}.stripeAccount`,
    defaultMessage: 'Stripe Account'
  },
  selectBusinessType: {
    id: `${scope}.selectBusinessType`,
    defaultMessage: 'Select Business Type'
  },
  taxNumber: {
    id: `${scope}.taxNumber`,
    defaultMessage: 'If Business or Sole Trader - VAT/IVA/NIF Tax Number [optional]'
  },
  taxNumberLabel: {
    id: `${scope}.taxNumberLabel`,
    defaultMessage: 'VAT/IVA/NIF Tax Number'
  },
  business: {
    id: `${scope}.business`,
    defaultMessage: 'Business'
  },
  soleTrader: {
    id: `${scope}.soleTrader`,
    defaultMessage: 'Sole Trader / Freelancer'
  },
  individual: {
    id: `${scope}.individual`,
    defaultMessage: 'Individual (Default)'
  },
  businessStatusTooltip: {
    id: `${scope}.businessStatusTooltip`,
    defaultMessage:
      'Informational Pop Up: Business and Sole Traders will be required to enter a Tax Number. Individuals will be treated as non-business customers and taxes such as VAT will be added to fees.'
  },
  taxNumberTooltip: {
    id: `${scope}.taxNumberTooltip`,
    defaultMessage:
      'Informational Pop Up: If you do not enter a tax number you will be treated as an individual and taxes such as VAT will be charged to fees.'
  },
  currencyTooltip: {
    id: `${scope}.currency_tooltip`,
    defaultMessage: 'Tips must be taken in the same currency as your Revolut account'
  },
  revolut: {
    id: `${scope}.revolut`,
    defaultMessage: 'Revolut'
  },
  revolutSynced: {
    id: `${scope}.revolutSynced`,
    defaultMessage: 'Revolut Synced'
  },
  selectRevolut: {
    id: `${scope}.selectRevolt`,
    defaultMessage: 'Select Revolut as your primary payment account to start receiving payments'
  },
  getPaidWithRevolut: {
    id: `${scope}.getPaidWithRevolut`,
    defaultMessage: 'Or get paid with Revolut.'
  },
  notActive: {
    id: `${scope}.notActive`,
    defaultMessage: 'Not Active'
  },
  addRevolutDetails: {
    id: `${scope}.addRevolutDetails`,
    defaultMessage: 'Add your Revolut details to get paid directly to the banking app.'
  },
  businessDetails: {
    id: `${scope}.businessDetails`,
    defaultMessage:
      'If you are a business or sole trader and you do not wish to have VAT added to your fees, provide your tax number and we will take care of the rest!'
  }
});
