import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/resources';

export const deleteResource = id => {
  return axios
    .delete(`${url}/delete/${id}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchTipDirectResources = id => {
  return axios
    .get(`${url}${id ? `?id=${id}` : ''}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const createResource = payload => {
  return axios
    .post(`${url}/add`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const editResource = payload => {
  return axios
    .post(`${url}/edit`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
