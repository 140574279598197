import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { printDuration } from '../../../../utils/duration';
import messages from './messages';
import { useIntl } from 'react-intl';
import ShortTourSummary from './ShortTourSummary';

const useStyles = makeStyles(theme => {
  const color = theme.palette.primary.main;
  return {
    date: {
      color,
      fontWeight: 'bold',
      fontSize: '14px'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '12px'
    },
    value: {
      color,
      fontWeight: 'bold',
      fontSize: '12px'
    }
  };
});

const formatTimeslot = timeslot => {
  const d = new Date();
  d.setHours(timeslot.hours);
  d.setMinutes(timeslot.minutes);

  return format(d, 'h:mmaaa');
};

export default function FullTourSummary({
  title,
  shortDescription,
  date,
  duration,
  languages,
  startingTime,
  operationalHours
}) {
  const classes = useStyles();
  const intl = useIntl();

  let timeInfoTitle, timeInfoText;
  if (startingTime) {
    timeInfoTitle = intl.formatMessage(messages.startingTime);
    timeInfoText = formatTimeslot(startingTime);
  } else {
    timeInfoTitle = intl.formatMessage(messages.operationalHours);
    timeInfoText = `${formatTimeslot(operationalHours.start)} - ${formatTimeslot(
      operationalHours.end
    )}`;
  }

  return (
    <div>
      <ShortTourSummary shortDescription={shortDescription} title={title} />
      <div className={classes.date}>{format(date, 'iii do MMM yyyy')}</div>
      <div>
        <span className={classes.title}>{timeInfoTitle}: &nbsp;</span>
        <span className={classes.value}>{timeInfoText}</span>
        {'  '}
        <span className={classes.title}>{intl.formatMessage(messages.duration)}: &nbsp;</span>
        <span className={classes.value}>{printDuration(intl, duration)}</span>
      </div>
    </div>
  );
}
