import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, CircularProgress } from '@material-ui/core';
import claimtip from '../../../assets/images/payout_page/Money.svg';
import PoweredBy from '../../../components/PoweredBy';
import { generateUserAccessToken } from '../../../api/astra';
import queryString from 'query-string';
import { RemitPayPalPaymentIntentIfAble } from '../../../api/payout';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  paperHalfHeight: {
    padding: theme.spacing(2)
  },
  paperContainer: {
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(2),
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  bluePaper: {
    backgroundColor: '#6997ff',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
    padding: '8px'
  },
  bluePaperLeadStat: {
    fontSize: '27px',
    textAlign: 'right'
  },
  bluePaperIcon: {
    float: 'right',
    fontSize: '12px'
  },
  iconButton: {
    width: '36px'
  },
  smallHeadingText: {
    fontSize: '13.4px',
    fontWeight: 'bold',
    color: '#565555'
  },
  vSmallHeadingText: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#565555'
  },
  vSmallHeadingTextBlue: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#5ea5ff'
  },
  whitePaperLeadStat: {
    fontSize: '27px',
    textAlign: 'center',
    color: '#5ea5ff',
    fontWeight: 'bold',
    margin: 'auto'
  },
  whitePaperDisclaimer: {
    fontSize: '9px',
    color: '#565555',
    fontWeight: 'bold'
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  container: {},
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto'
  },
  paymentMethods: {
    border: '1px solid red'
  },
  outerSurface: {
    backgroundColor: '#f9f9f9',
    padding: 22,
    margin: 11,
    borderRadius: 16
  },
  innerSurface: {
    backgroundColor: 'white',
    padding: 12,
    paddingLeft: 12,
    borderRadius: 16
  },
  innerSurfaceButton: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    padding: 12,
    borderRadius: 16,
    marginBottom: '16px'
  },
  boldHeading: {
    color: 'black',
    fontWeight: 'bold'
  },
  imageStyle: {
    margin: 22
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonStyle: {
    padding: 8,
    width: '100%'
  },
  iconImage: {
    width: '35px',
    height: '35px'
  },
  iconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    borderRadius: 35,
    marginRight: 12,
    width: '22px',
    height: '22px'
  },
  largeIconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignitems: 'center',
    borderRadius: 82,
    marginRight: 12,
    width: '82px',
    height: '82px'
  },
  payoutLinkBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.22px',
    color: '#000'
  },
  iconAndLabel: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function PayoutLink() {
  const classes = useStyles();
  useEffect(generateUserAccessTokenViaAPI, []);

  async function generateUserAccessTokenViaAPI() {
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get('code');
    let astraCode = code;
    console.log('generating access code', code);
    let resp = await generateUserAccessToken(astraCode);
    //Note cancel previous payout link through revolut, should pass payout link instead of paymentintent
    console.log('Resp was', resp);
    if (resp.cardConnectUrl) {
      window.location = resp.cardConnectUrl;
    }
    //Redirect into astra
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <br />
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Paper className={classes.largeIconContainer} elevation={3}>
            <img
              style={{ width: '100%' }}
              src={require('../../../assets/images/payout_page/Encryption.svg')}
            />
          </Paper>
          <br />
          <div>
            <span className={classes.boldHeading}>Linking Astra & TripAdmit</span>
          </div>
        </div>

        <br />
        <Paper className={classes.outerSurface} elevation={0}>
          <center>
            <CircularProgress />
          </center>
        </Paper>
      </div>
    </div>
  );
}
