export const ONBOARDING_TIPPING_OPTION_V1 = {
  name: 'onboarding_tipping_option_v1',
  values: {
    Bespoke: 'bespoke',
    Quick: 'quick'
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? ONBOARDING_TIPPING_OPTION_V1.values.Bespoke
      : ONBOARDING_TIPPING_OPTION_V1.values.Quick;
  },
};

export default [ONBOARDING_TIPPING_OPTION_V1];
