import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.LinkInformation';

export default defineMessages({
  downloadImage: {
    id: `${scope}.download_image`,
    defaultMessage: 'Download Image'
  },
  copiedToClipboard: {
    id: `${scope}.copied_to_clipboard`,
    defaultMessage: 'Copied to clipboard'
  },
  orderCards: {
    id: `${scope}.orderCards`,
    defaultMessage: 'Order Cards'
  },
  orderCardsDesc: {
    id: `${scope}.orderCardsDesc`,
    defaultMessage: 'Need additional Tip Direct cards? Order them through our website.'
  },
  personalCodeTitle: {
    id: `${scope}.personalCodeTitle`,
    defaultMessage: 'Personal QR Code'
  },
  personalCodeDesc: {
    id: `${scope}.personalCodeDesc`,
    defaultMessage:
      'Using your QR code you can still accept digital tips if you forget or lose your Tip Direct card.'
  },
  personalLinkTitle: {
    id: `${scope}.personalLinkTitle`,
    defaultMessage: 'Personal Tipping Link'
  },
  personalLinkDesc: {
    id: `${scope}.personalLinkDesc`,
    defaultMessage:
      'Share your unique link with customers via text or email to provide other options for them to leave tips.'
  },
  previewTippingTitle: {
    id: `${scope}.previewTippingTitle`,
    defaultMessage: 'Preview Tipping Flow'
  },
  previewTippingDesc: {
    id: `${scope}.previewTippingDesc`,
    defaultMessage:
      'Do you want to see what your tipping flow looks like for customers? Preview it here.'
  },
  viewTippingPage: {
    id: `${scope}.viewTippingPage`,
    defaultMessage: 'View Tipping Page'
  },
  downloadPrintout: {
    id: `${scope}.downloadPrintout`,
    defaultMessage: 'Download Printout'
  },
  viewGuideGallery: {
    id: `${scope}.viewGuideGallery`,
    defaultMessage: 'View Guide Gallery'
  },
  downloadGalleryQR: {
    id: `${scope}.downloadGalleryQR`,
    defaultMessage: 'Download Gallery QR'
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: 'Copy Link'
  },
  share: {
    id: `${scope}.share`,
    defaultMessage: 'Share'
  }
});
