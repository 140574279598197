import { call, put, takeLatest } from '@redux-saga/core/effects';
import { fetchTeamDetails, saveSplitBreakdown } from '../../../../api/taptotip/group';
import { extractStatusBarError, snackbarStatus } from '../../../../containers/StatusBar';
import { setSnackbarStatus } from '../../../../containers/StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* fetchTeamDetailsWorker({ payload }) {
  try {
    const teamDetails = yield call(fetchTeamDetails, payload);

    yield put(actions.fetchTeamDetailsSuccess(teamDetails));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* saveSplitBreakdownWorker({ payload }) {
  try {
    yield call(saveSplitBreakdown, payload);

    yield put(actions.saveSplitBreakdownSuccess());
    yield put(actions.fetchTeamDetails(payload.groupId));
  } catch (err) {
    yield put(actions.saveSplitBreakdownFailure());
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* teamSettingsSaga() {
  yield takeLatest(types.FETCH_TEAM_DETAILS, fetchTeamDetailsWorker);
  yield takeLatest(types.SAVE_SPLIT_BREAKDOWN, saveSplitBreakdownWorker);
}
