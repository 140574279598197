import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  resetPasswordToken: null,
  error: null
};

const fetchResetPasswordTokenSuccess = (state, resetPasswordToken) => ({
  ...state,
  resetPasswordToken,
  error: null
});

const fetchResetPasswordTokenError = (state, error) => ({
  ...state,
  error
});

export default createReducer(
  {
    [actionTypes.FETCH_RESET_TOKEN_SUCCESS]: fetchResetPasswordTokenSuccess,
    [actionTypes.FETCH_RESET_TOKEN_ERROR]: fetchResetPasswordTokenError
  },
  initialState
);
