import { defineMessages } from 'react-intl';

export const scope = 'app.components.TapToTip.CreateOrEditTipDirectResource';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Create New Resource'
  },
  editTitle: {
    id: `${scope}.editTitle`,
    defaultMessage: 'Edit Resource'
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage: 'Create a new resource for guides to use'
  },
  editSubTitle: {
    id: `${scope}.editSubTitle`,
    defaultMessage: 'Edit details'
  },
  emojiTitle: {
    id: `${scope}.subTitle`,
    defaultMessage: 'Select an icon'
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: 'Resource Name'
  },
  descriptionLabel: {
    id: `${scope}.descriptionLabel`,
    defaultMessage: 'Resource Description'
  },
  createResource: {
    id: `${scope}.createResource`,
    defaultMessage: 'Create Resource'
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save Changes'
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Cancel'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  resourceIcon: {
    id: `${scope}.resourceIcon`,
    defaultMessage: 'Resource Icon'
  }
});
