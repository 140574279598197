import { Analytics } from '@analytics';
import ButtonWithMenu from '@components/Common/ButtonWithMenu';
import CustomizableModal from '@components/Common/CustomizableModal';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import localizationMessages from '@components/Resources/ResourcesTable/messages';
import AccessControlledButton from '@components/TapToTip/AccessControlledButton';
import AccessControlledTableBody from '@components/TapToTip/AccessControlledTableBody';
import CreateTourModal from '@components/TapToTip/CreateTourModal';
import ManageGuidesModal from '@components/TapToTip/ManageGuidesModal';
import { MenuItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { StyleBreakpoints } from '@utils/constants';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import MaterialTable from 'material-table';
import { default as React, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from './actions';
import { StatusBackgroundColor, StatusForeColor, StatusType } from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '4px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    border: 'none',
    cursor: 'pointer',
    color: '#000'
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    },
    width: '100%'
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    width: '80%',
    maxHeight: '90vh',
    flexWrap: 'wrap',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      padding: '24px 16px'
    }
  },
  table: {
    width: '100%'
  },
  topItems: {
    margin: '0 0 20px auto',
    display: 'flex',
    gap: '15px'
  },
  status: {
    textAlign: 'center',
    width: '86px',
    padding: '4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '4px'
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  }
}));

function mapTourForEditing(tour) {
  const lang = tour.translations.primaryLanguage;
  return {
    _id: tour._id,
    primaryLanguage: lang,
    title: tour.translations.translations[lang].data.title,
    description: tour.translations.translations[lang].data.longDescription,
    reviewSites: tour.reviewSites
  };
}

const TourStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: StatusBackgroundColor[status],
        color: StatusForeColor[status]
      }}>
      {StatusType[status]}
    </div>
  );
};

const TipDirectToursTable = ({
  tours,
  fetchTours,
  addTapToTipTour,
  editTapToTipTour,
  agentsProfiles,
  fetchAgentsProfiles,
  multipleAssign,
  deleteTour,
  isLoading
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isAddTourModalOpen, setIsAddTourModalOpen] = useState(false);
  const [isManageGuidesOpen, setIsManageGuidesOpen] = useState(false);

  useInjectReducer({ key: 'tipTours', reducer });
  useInjectSaga({ key: 'tipTours', saga });

  const [tourDataToEdit, setTourDataToEdit] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tourToDelete, setTourToDelete] = useState({});

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleTourRemove = tours => {
    deleteTour(tours._id);
    Analytics.track('delete tour clicked');
    handleModalClose();
  };

  useEffect(() => {
    if (!tours?.length) {
      fetchTours();
    }
    if (!agentsProfiles?.length) {
      fetchAgentsProfiles();
    }
  }, []);

  function openEditTourModal(data) {
    setTourDataToEdit(mapTourForEditing(data));
    setIsAddTourModalOpen(true);
  }

  function openAddTourModal() {
    setTourDataToEdit(null);
    setIsAddTourModalOpen(true);
    Analytics.trackViewFeature('Tours');
    Analytics.track('create TTT tour opened');
  }

  const getMenuBody = rowData => [
    <AccessControlledButton
      key={rowData._id}
      feature="PostTipReviewCollection"
      as="MenuItem"
      title={intl.formatMessage(messages.editTour)}
      onClick={() => {
        openEditTourModal(rowData);
        Analytics.track('edit TTT tour opened');
      }}
    />,
    <MenuItem
      key={rowData._id}
      onClick={() => {
        setIsDeleteModalOpen(true);
        setTourToDelete(rowData);
        Analytics.track('delete TTT tour opened');
      }}>
      {intl.formatMessage(messages.deleteTour)}
    </MenuItem>
  ];

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      customSort: (a, b) => {
        return (a.reviewSites?.length > 0) - (b.reviewSites?.length > 0);
      },
      render: rowData => (
        <TourStatus status={rowData.numberTourSites ? 'active' : 'incomplete'} classes={classes} />
      )
    },
    {
      field: 'title',
      title: intl.formatMessage(messages.tour)
    },
    {
      field: 'numberTourSites',
      title: intl.formatMessage(messages.reviewLinks),
      defaultSort: 'desc',
      render: rowData => {
        return <div className={classes.status}>{rowData.numberTourSites}</div>;
      }
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'manage',
      sorting: false,
      headerStyle: {
        paddingRight: '25px',
        textAlign: 'right'
      },
      cellStyle: {
        textAlign: 'right'
      },
      render: rowData => <ButtonWithMenu menuBody={getMenuBody(rowData)} />
    }
  ];

  const toursWithTitles = tours?.map(tour => {
    return {
      ...tour,
      title: tour.translations.translations[tour.translations.primaryLanguage].data.title,
      numberTourSites: tour.reviewSites
        ? tour.reviewSites.filter(site => site.url && site.url.length).length
        : 0
    };
  });

  return (
    <>
      <TopNav />
      <div className={classes.outerContainer}>
        <TipDirectLeftMenu selectedTab={'Tours'} />
        <div className={classes.container}>
          <div className={classes.topItems}>
            <AccessControlledButton
              feature="PostTipReviewCollection"
              tooltipTitle="Create new tours to collect reviews for"
              title={intl.formatMessage(messages.createTour)}
              onClick={openAddTourModal}
            />
          </div>
          <MaterialTable
            isLoading={isLoading}
            className={classes.table}
            title={intl.formatMessage(messages.tours)}
            columns={columns}
            data={toursWithTitles}
            components={{
              Container: props => <Paper className={classes.striped} {...props} />
            }}
            options={{
              pageSize: 10,
              tableLayout: 'auto',
              emptyRowsWhenPaging: false,
              headerStyle: {
                whiteSpace: 'nowrap',
                position: 'sticky',
                top: '0'
              },
              draggable: false,
              cellStyle: {
                whiteSpace: 'nowrap',
                width: 'fit-content'
              },
              maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
            }}
            localization={{
              pagination: {
                labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
                labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
                firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
                firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
                previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
                previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
                nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
                nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
                lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
                lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
              },
              toolbar: {
                searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
                searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
              },
              body: {
                emptyDataSourceMessage: (
                  <AccessControlledTableBody
                    feature="PostTipReviewCollection"
                    title={intl.formatMessage(messages.emptyBodyTitle)}
                    description={intl.formatMessage(messages.emptyBodyDescription)}
                  />
                )
              }
            }}
          />

          <CreateTourModal
            isOpen={isAddTourModalOpen}
            setIsOpen={setIsAddTourModalOpen}
            addTapToTipTour={addTapToTipTour}
            setIsManageGuidesOpen={setIsManageGuidesOpen}
            updateTapToTipTour={editTapToTipTour}
            tourDetails={tourDataToEdit}
          />
          <ManageGuidesModal
            isOpen={isManageGuidesOpen}
            setIsOpen={setIsManageGuidesOpen}
            setIsAddTourModalOpen={setIsAddTourModalOpen}
            guides={agentsProfiles}
            tourDetails={tourDataToEdit}
            multipleAssign={multipleAssign}
          />
          <CustomizableModal
            isOpen={isDeleteModalOpen}
            onClose={handleModalClose}
            handleHeaderCloseButton={handleModalClose}
            title={intl.formatMessage(messages.confirmAction)}
            body={
              <p>
                {intl.formatMessage(messages.removeTourConfirm)}: <b>{tourToDelete.title}</b>
              </p>
            }
            handleFooterCloseButton={handleModalClose}
            primaryActionButtonData={{
              content: intl.formatMessage(messages.confirm),
              onClick: () => handleTourRemove(tourToDelete),
              isDisabled: false
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  agentsProfiles: state.tipTours?.agentsProfiles,
  tours: state.tipTours?.tours,
  isLoading: state.tipTours?.isLoading
});

const mapDispatchToProps = dispatch => ({
  fetchAgentsProfiles: () => dispatch(actions.fetchAgentsProfiles()),
  addTapToTipTour: payload => dispatch(actions.addTapToTipTour(payload)),
  editTapToTipTour: payload => dispatch(actions.editTapToTipTour(payload)),
  fetchTours: () => dispatch(actions.fetchTours()),
  multipleAssign: (tourIds, guideIds) => dispatch(actions.multipleAssign({ tourIds, guideIds })),
  deleteTour: payload => dispatch(actions.deleteTour(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TipDirectToursTable);
