import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.ProPlanModal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Upgrade to our Pro Plan to use this feature'
  },
  bodyTitle: {
    id: `${scope}.bodyTitle`,
    defaultMessage: '30 days of Pro for free. Cancel anytime.'
  },
  bodyDesc: {
    id: `${scope}.bodyDesc`,
    defaultMessage:
      'Become a master at collecting tips and reviews. The plan includes two locations/cities'
  },
  includesBlockTitle: {
    id: `${scope}.includesBlockTitle`,
    defaultMessage: 'This Includes:'
  },
  essentialFeatures: {
    id: `${scope}.essentialFeatures`,
    defaultMessage: 'All Essential features'
  },
  reviewCollection: {
    id: `${scope}.reviewCollection`,
    defaultMessage: 'Review Collection & AI Assist'
  },
  resourceCollection: {
    id: `${scope}.resourceCollection`,
    defaultMessage: 'Resource Collection Points'
  },
  guideTipSharing: {
    id: `${scope}.guideTipSharing`,
    defaultMessage: 'Guide Tip Sharing'
  },
  nfcCards: {
    id: `${scope}.nfcCards`,
    defaultMessage: 'Branded NFC Cards'
  },
  customerService: {
    id: `${scope}.customerService`,
    defaultMessage: 'Dedicated customer service inbox'
  },
  tryProForFree: {
    id: `${scope}.tryProForFree`,
    defaultMessage: 'Try Pro for Free'
  },
  viewAllPlans: {
    id: `${scope}.viewAllPlans`,
    defaultMessage: 'View all Plans'
  }
});
