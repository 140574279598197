import { Analytics } from '@analytics';
import EmojiAvatar from '@components/EmojiAvatar';
import { StyleBreakpoints } from '@constants';
import { MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { regionalURLs } from '@utils/constants';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import ButtonWithMenu from '../../Common/ButtonWithMenu';
import AccessControlledButton from '../AccessControlledButton';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    height: '147px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 15px 22px 23px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',

    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      maxWidth: '400px'
    },
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      maxWidth: '350px'
    }
  },
  cardTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '7px'
  },
  icon: {
    width: '51px',
    minWidth: '51px',
    height: '51px',
    borderRadius: '50%',
    marginRight: '16px'
  },
  details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000000'
  },
  descriptionContainer: {
    maxHeight: '35px'
  },
  description: {
    fontSize: '12px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#000000',
    '@supports (-webkit-line-clamp: 2)': {
      whiteSpace: 'initial',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical'
    }
  },
  cardCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '40px',
    alignItems: 'center',
    marginRight: '-7px'
  },
  cardCount: {
    minWidth: '108px',
    height: '22px',
    borderRadius: '12px',
    backgroundColor: '#d8fdd8',
    color: '#018101',
    fontSize: '11px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    marginRight: '10px',
    justifyContent: 'center'
  },
  resourceButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  menuItemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 600,
    color: '#000',
    minWidth: 'fit-content'
  }
}));

const ResourceListItem = ({ resource, deleteResource, editResource, downloadQRCode }) => {
  const intl = useIntl();
  const classes = useStyles();
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();

  const linkUrl =
    (regionalURLs[process.env.REGION] || regionalURLs.US) + `t/c/${resource.assignedCardIds[0]}`;

  const getMenuBody = () => {
    return [
      <MenuItem
        key={`qrcode-${resource._id}`}
        disabled={resource.assignedCardsAmount === 0}
        className={classes.menuItemText}
        onClick={() => {
          downloadQRCode(linkUrl, resource.name);
          Analytics.track('Download QR code clicked');
        }}>
        {intl.formatMessage(messages.downloadQRCode)}
      </MenuItem>,
      <AccessControlledButton
        key={`edit-${resource._id}`}
        feature={'ResourceTippingPoints'}
        as="MenuItem"
        elementStyle={classes.menuItemText}
        title={intl.formatMessage(messages.editResource)}
        onClick={() => {
          editResource(resource);
          Analytics.track('edit resource opened');
        }}
      />,
      <MenuItem
        key={`delete-${resource._id}`}
        className={classes.menuItemText}
        disabled={!isFeatureForCompanyEnabled('ResourceTippingPoints')}
        onClick={() => {
          deleteResource(resource);
          Analytics.track('delete resource opened');
        }}>
        {intl.formatMessage(messages.deleteResource)}
      </MenuItem>
    ];
  };

  return (
    <>
      <div className={classes.content}>
        <div className={classes.cardTopRow}>
          <div className={classes.icon}>
            <EmojiAvatar code={resource.emoji || ':credit_card:'} />
          </div>
          <div className={classes.cardCountWrapper}>
            <div className={classes.cardCount}>
              {`${intl.formatMessage(messages.activeCards)}: ${resource.assignedCardsAmount}`}
            </div>
            <div className={classes.resourceButton}>
              <ButtonWithMenu iconOnly={true} menuBody={getMenuBody()} />
            </div>
          </div>
        </div>
        <div className={classes.details}>
          <div className={classes.name}>{resource.name}</div>
          <div className={classes.descriptionContainer}>
            <Tooltip enterTouchDelay={0} title={resource.description}>
              <div className={classes.description}>{resource.description}</div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceListItem;
