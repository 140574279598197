import { defineMessages } from 'react-intl';

export const scope = 'app.components.CountryDropdown';

export default defineMessages({
  countryError: {
    id: `${scope}.countryError`,
    defaultMessage: 'Country is required'
  }
});
