export const FETCH_AGENTS_PROFILES = 'FETCH_AGENTS_PROFILES';
export const FETCH_AGENTS_PROFILES_SUCCESS = 'FETCH_AGENTS_PROFILES_SUCCESS';

export const FETCH_REVIEWS_STATISTIC = 'FETCH_REVIEWS_STATISTIC';
export const FETCH_REVIEWS_STATISTIC_SUCCESS = 'FETCH_REVIEWS_STATISTIC_SUCCESS';

export const ACTION_ERROR = 'ACTION_ERROR';

export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
