import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  agentsProfiles: [],
  error: null,
  isLoading: false,
  activeTours: []
};

const fetchAgentsProfiles = state => ({
  ...state,
  isLoading: true
});

const fetchAgentsProfilesSuccess = (state, agentsProfiles) => ({
  ...state,
  agentsProfiles,
  isLoading: false
});

const fetchActiveToursSuccess = (state, activeTours) => ({
  ...state,
  activeTours
});

const facedError = (state, error) => ({
  ...state,
  error
});

export default createReducer(
  {
    [actionTypes.FETCH_AGENTS_PROFILES]: fetchAgentsProfiles,
    [actionTypes.FETCH_AGENTS_PROFILES_SUCCESS]: fetchAgentsProfilesSuccess,
    [actionTypes.FETCH_ACTIVE_TOURS_SUCCESS]: fetchActiveToursSuccess,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
