import EmojiAvatar from '@components/EmojiAvatar';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { memo } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  avatar: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  name: {
    marginLeft: '16px',
    flex: 1
  }
});

const GroupResourceDetails = ({
  name,
  emoji = ':credit_card:',
  shouldShowRemoveButton,
  handleRemoveFromTeam
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.avatar}>
        <EmojiAvatar code={emoji} />
      </div>
      <div className={classes.name}>{name}</div>
      {shouldShowRemoveButton && (
        <Button onClick={handleRemoveFromTeam}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default memo(GroupResourceDetails);
