import EmojiAvatar from '@components/EmojiAvatar';
import { StyleBreakpoints, resourceEmoji } from '@constants';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '400px',
      justifyContent: 'revert'
    }
  },
  emojiOptions: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '400px'
    }
  },
  emojiOption: {
    marginRight: '16px',
    marginBottom: '16px',
    height: '60px',
    width: '60px'
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#000',
    marginBottom: '2px'
  },
  subTitle: {
    fontSize: '13px',
    color: '#a6a6a6',
    marginBottom: '24px'
  },
  textField: {
    marginBottom: '24px'
  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      justifyContent: 'end'
    }
  },
  createButton: {
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      width: 'auto'
    }
  },
  backBtn: {
    fontSize: '13px',
    color: '#000',
    padding: 0,
    marginBottom: '6px'
  },
  resourceIconText: {
    fontSize: '20px',
    color: '#000',
    fontWeight: 600,
    marginBottom: '14px'
  },
  backButton: {
    marginTop: '12px',
    color: '#c2c2c2'
  }
}));

const CreateOrEditTipDirectResource = ({
  submitCreateResource,
  closeCreateResource,
  existingResource,
  editResource
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [name, setName] = useState(existingResource?.name || '');
  const [description, setDescription] = useState(existingResource?.description || '');
  const [selectedEmoji, setSelectedEmoji] = useState(existingResource?.emoji || ':credit_card:');
  const isCreateButtonDisabled = name.trim() === '';
  const isEditButtonDisabled =
    name === existingResource?.name &&
    description === existingResource?.description &&
    selectedEmoji === existingResource?.emoji;

  const handleCreateResourceClicked = () => {
    if (!name) return;

    if (existingResource) {
      editResource({ id: existingResource._id, name, description, emoji: selectedEmoji });
      return;
    }

    submitCreateResource(name, description, selectedEmoji);
  };
  const handleTitleUpdate = e => {
    setName(e.target.value);
  };

  const handleDescriptionUpdate = e => {
    setDescription(e.target.value);
  };

  const handleBackClick = () => {
    closeCreateResource();
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Button
          className={classes.backBtn}
          onClick={handleBackClick}
          color="primary"
          startIcon={<ArrowBackIcon />}>
          {intl.formatMessage(messages.resources)}
        </Button>
        <div className={classes.title}>
          {intl.formatMessage(existingResource ? messages.editTitle : messages.title)}
        </div>
        <div className={classes.subTitle}>
          {intl.formatMessage(existingResource ? messages.editSubTitle : messages.subTitle)}
        </div>

        {existingResource ? (
          <div className={classes.resourceIconText}>
            {intl.formatMessage(messages.resourceIcon)}
          </div>
        ) : (
          <div className={classes.subTitle}>{intl.formatMessage(messages.emojiTitle)}</div>
        )}
        <div className={classes.emojiOptions}>
          {resourceEmoji.map(emo => {
            return (
              <div className={classes.emojiOption} key={emo}>
                <EmojiAvatar
                  isClickable
                  code={emo}
                  selected={emo === selectedEmoji}
                  onClick={() => {
                    setSelectedEmoji(emo);
                  }}
                />
              </div>
            );
          })}
        </div>
        <TextField
          inputProps={{
            maxLength: 40
          }}
          fullWidth
          variant="outlined"
          color="primary"
          value={name}
          classes={{
            root: classes.textField
          }}
          InputLabelProps={{
            shrink: true
          }}
          type="string"
          onChange={handleTitleUpdate}
          label={intl.formatMessage(messages.nameLabel)}
          required
        />
        <TextField
          inputProps={{
            maxLength: 150
          }}
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          color="primary"
          classes={{
            root: classes.textField
          }}
          InputLabelProps={{
            shrink: true
          }}
          type="string"
          value={description}
          onChange={handleDescriptionUpdate}
          label={intl.formatMessage(messages.descriptionLabel)}
        />
      </div>

      <div className={classes.bottomButtons}>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          disabled={existingResource ? isEditButtonDisabled : isCreateButtonDisabled}
          onClick={handleCreateResourceClicked}>
          {existingResource
            ? intl.formatMessage(messages.saveChanges)
            : intl.formatMessage(messages.createResource)}
        </Button>
        {existingResource && (
          <Button className={classes.backButton} color="secondary" onClick={closeCreateResource}>
            {intl.formatMessage(messages.back)}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateOrEditTipDirectResource;
