import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountCircle as AccountIcon,
  AddCircle as AddCircleIcon,
  ClearOutlined as CancelIcon
} from '@material-ui/icons';
import { Field } from 'formik';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from '../../../containers/TipDirectPages/TippingProfile/messages';
import { StyleBreakpoints } from '../../../utils/constants';
// import messages from '../../TapToTip/Profile/messages';

const useStyles = makeStyles(theme => ({
  validationErrorMessage: {
    color: 'red'
  },
  textField: {
    marginTop: '20px',
    marginBottom: '8px',
    width: '100%',
    minWidth: '250px',
    height: '70px',
    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    }
  },
  headerText: {
    fontSize: 13,
    color: '#6b6b6b',
    fontWeight: 'bold'
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  profileImage: {
    width: '50%',
    maxWidth: '250px',
    marginBottom: '10px',
    marginTop: '10px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      filter: 'brightness(80%)'
    },

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '30%'
    }
  },
  profileDefaultImg: {
    width: '90%',
    maxWidth: '250px',
    color: '#d8d8d8',
    marginTop: '10px',
    marginBottom: '20px',
    fontSize: '180px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '&:hover': {
      filter: 'brightness(80%)'
    },

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      fontsize: 120
    }
  },
  uploadButton: {
    width: '70%',
    minWidth: '250px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  }
}));

const Profile = ({ profile, errors, touched, values, uploadUserProfileImage }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);

  const handleDrop = files => {
    if (files[0]) {
      uploadUserProfileImage(files[0]);
    }
    setIsDropzoneOpen(false);
  };

  const Fields = {
    Image: 'profileImage',
    Bio: 'profileBio'
  };

  const getFieldContent = (form, field) => {
    switch (field) {
      case Fields.Image: {
        return (
          <Grid className={classes.headerText}>
            {intl.formatMessage(messages.imageHeader)}
            <Grid className={classes.imageContainer}>
              {profile?.avatarUrl ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                  onClick={() => setIsDropzoneOpen(true)}
                  src={profile?.avatarUrl}
                  className={classes.profileImage}
                  alt="Profile Avatar"
                />
              ) : (
                <AccountIcon
                  onClick={() => setIsDropzoneOpen(true)}
                  className={classes.profileDefaultImg}
                />
              )}

              <Button
                className={classes.uploadButton}
                variant="contained"
                color="primary"
                endIcon={<AddCircleIcon />}
                onClick={() => setIsDropzoneOpen(true)}>
                {intl.formatMessage(messages.imageButton)}
              </Button>
            </Grid>
            <DropzoneDialog
              cancelButtonText={intl.formatMessage(messages.imageCancel)}
              dialogTitle={
                <div>
                  <span>{intl.formatMessage(messages.imageHeader)}</span>
                  <CancelIcon onClick={() => setIsDropzoneOpen(false)} />
                </div>
              }
              submitButtonText={intl.formatMessage(messages.imageSubmit)}
              open={isDropzoneOpen}
              onSave={handleDrop}
              maxFileSize={5000000}
              filesLimit={1}
              onClose={() => setIsDropzoneOpen(false)}
            />
          </Grid>
        );
      }

      case Fields.Bio:
        return (
          <Grid className={classes.headerText}>
            {intl.formatMessage(messages.bioHeader)}

            <TextField
              multiline
              rows={2}
              className={classes.textField}
              label={intl.formatMessage(messages.bioLabel)}
              InputLabelProps={{ shrink: true }}
              placeholder={intl.formatMessage(messages.bioPlaceholder)}
              variant="outlined"
              value={values.description}
              onChange={e => {
                form.setFieldValue('description', e.target.value);
              }}
              inputProps={{ maxLength: 200 }}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {Object.values(Fields).map(field => (
        <div key={field}>
          <Field name={field}>
            {({ form }) => {
              const fieldContent = getFieldContent(form, field);
              return fieldContent;
            }}
          </Field>
          {errors[field] && touched[field] && (
            <div className={classes.validationErrorMessage}>{errors[field]}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Profile;
