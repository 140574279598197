import { defineMessages } from 'react-intl';

export const scope = 'app.components.TapToTip.ListResources';

export default defineMessages({
  createResource: {
    id: `${scope}.createResource`,
    defaultMessage: 'Create Resource'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Resources'
  },
  confirmAction: {
    id: `${scope}.confirmAction`,
    defaultMessage: 'Confirm deletion'
  },
  removeResourceConfirm: {
    id: `${scope}.removeResourceConfirm`,
    defaultMessage: 'Are you sure you which to delete this resource?'
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm'
  },
  deleteAssigned: {
    id: `${scope}.deleteAssigned`,
    defaultMessage:
      'This resource already has cards assigned. Deleting this resource will unassign all cards.'
  }
});
