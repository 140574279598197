import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  resources: [],
  isLoading: true
};

const fetchResourcesInit = state => ({
  ...state,
  error: null,
  isLoading: true
});

const fetchResources = (state, resources) => ({
  ...state,
  error: null,
  resources,
  isLoading: false
});

const facedError = (state, error) => ({
  ...state,
  error
});

export default createReducer(
  {
    [actionTypes.FETCH_RESOURCES]: fetchResourcesInit,
    [actionTypes.FETCH_RESOURCES_SUCCESS]: fetchResources,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
