import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  agentsProfiles: [],
  reviewsStatistic: [],
  isLoading: false
};

const fetchAgentsProfiles = state => ({
  ...state,
  isLoading: true
});

const fetchAgentsProfilesSuccess = (state, agentsProfiles) => ({
  ...state,
  agentsProfiles,
  isLoading: false
});

const fetchReviewsStatistic = state => ({
  ...state,
  isLoading: true
});

const fetchReviewsStatisticSuccess = (state, reviewsStatistic) => ({
  ...state,
  reviewsStatistic,
  isLoading: false
});

export default createReducer(
  {
    [actionTypes.FETCH_AGENTS_PROFILES]: fetchAgentsProfiles,
    [actionTypes.FETCH_AGENTS_PROFILES_SUCCESS]: fetchAgentsProfilesSuccess,
    [actionTypes.FETCH_REVIEWS_STATISTIC]: fetchReviewsStatistic,
    [actionTypes.FETCH_REVIEWS_STATISTIC_SUCCESS]: fetchReviewsStatisticSuccess
  },
  initialState
);
