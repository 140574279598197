import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/astra';

export const createUserIntent = body => {
  return axios
    .post(`${url}/userintent`, body)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const generateUserAccessToken = code => {
  return axios
    .post(`${url}/generate-user-access-token`, { code })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
export const confirmAstraUser = paymentIntentId => {
  return axios
    .post(`${url}/confirm`, { paymentIntentId })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const getUserCards = () => {
  return axios
    .get(`${url}/cards`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const getLocalUserCards = () => {
  return axios
    .get(`${url}/localcards`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const getReAuthURL = () => {
  return axios
    .get(`${url}/reauthurl`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const deleteCard = cardId => {
  return axios
    .delete(`${url}/card`, {
      params: { cardId }
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
