/* eslint-disable no-console */
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import queryString from 'query-string';
import * as R from 'ramda';
import React from 'react';

/*
English (United States)
German
Spanish
French
Dutch
Polish
Portuguese
Swedish
Japanese
Danish
Italian
Norvegian
Finnish
Russian
*/

const trans = {
  en: 'Book Now',
  de: 'Buchen Sie Jetzt',
  es: 'Reservar Ahora',
  fr: 'Reserve Maintenant',
  nl: 'Boek Nu',
  pl: 'Rezerwuj Teraz',
  pt: 'Reservar',
  sv: 'Boka Nu',
  ja: '今予約する',
  da: 'Book Nu',
  it: 'Prenota Ora',
  no: 'Bestill nå',
  fi: 'Varaa nyt',
  ru: 'Забронируйте сейчас'
};

export default class BookNowButton extends React.Component {
  constructor(props) {
    super(props);
    const qp = queryString.parse(this.props.location.search);
    this.state = {
      qp,
      language: queryString.parse(this.props.location.search).language || 'en',
      btntext: queryString.parse(this.props.location.search).btntext || null
    };
  }

  getFontSize() {
    var bookNowString = this.getBookNowString();
    var strLen = bookNowString.length;
    if (strLen <= 8) {
      return '22px';
    }
    if (strLen <= 12) {
      return '20px';
    }
    if (strLen <= 19) {
      return '16px';
    } else {
      return '14px';
    }
  }

  getBookNowString() {
    if (this.state.btntext) {
      return this.state.btntext;
    }
    if (trans[this.state.language]) {
      return trans[this.state.language];
    }
    return trans['en'];
  }

  notifyIframeOpenModal() {
    window.parent.postMessage(
      JSON.stringify({
        msgType: 'openIframe',
        tourId: this.state.qp.tourId,
        lang: this.state.language
      }),
      '*'
    );
  }

  async fetchTourDetails(currency) {
    this.setState({ isLoaded: false });
    try {
      const tour = await fetch(`/api/v1/tour/${this.state.qp.tourId}/raw-tour`)
        .then(res => res.json())
        .then(data => data.data);

      this.setState({
        isLoaded: true,
        item: tour,
        currency: currency
      });
    } catch (error) {
      console.error(error);
      this.setState({ isLoaded: true, error });
    }
  }

  async componentDidMount() {
    this.setState({ isLoaded: false });
    try {
      document.getElementById('app').style.backgroundColor = 'transparent';
      document.body.style.backgroundColor = 'transparent';
      document.getElementsByTagName('HTML')[0].style.backgroundColor = 'transparent';
      const saleSettings = await fetch(`/api/v1/tour/${this.state.qp.tourId}/sale-options`)
        .then(res => res.json())
        .then(res => res.data);
      const initCurrency = this.state.qp.currency;
      const newState = {
        currency:
          initCurrency && saleSettings.supportedCurrencies.includes(initCurrency)
            ? initCurrency
            : saleSettings.defaultCurrency,
        supportedCurrencies: saleSettings.supportedCurrencies,
        customization: saleSettings.customization
      };
      if (saleSettings.customization.fontUrl) {
        this.loadCustomFont(saleSettings.customization.fontUrl, saleSettings.companyId);
      }
      this.setState(newState);
      await this.fetchTourDetails(newState.currency);
    } catch (error) {
      console.error(error);
      this.setState({ isLoaded: true, error });
    }
  }

  loadCustomFont(fontUrl, companyId) {
    try {
      const fontFace = `Custom-company-font-${companyId}`;
      const font = new FontFace(fontFace, `url(${fontUrl})`, {
        style: 'normal',
        weight: 400
      });
      font.load().then(loadedFace => {
        document.fonts.add(loadedFace);
        this.setState({ customFont: fontFace });
      });
    } catch (err) {
      console.error('Failed to load custom font: ' + err.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.item.tourState != 'published') {
      return (
        <div
          style={{
            backgroundColor: 'rgb(245, 247, 250)',
            overflowY: 'hidden',
            height: '275px',
            fontSize: '18px'
          }}>
          <br />
          <center>We&apos;re sorry this tour is no longer available</center>
        </div>
      );
    }

    const {
      primaryColorHex,
      secondaryColorHex,
      companyId,
      buttonColorHex
    } = this.state.customization;
    const font = this.state.customFont;
    document.body.style.backgroundColor = 'transparent';
    return (
      <ThemeProvider
        theme={theme => {
          const newTheme = { ...theme };
          if (primaryColorHex) {
            newTheme.palette.primary = { main: primaryColorHex };
            newTheme.palette.info = { main: primaryColorHex };
          }

          if (buttonColorHex) {
            newTheme.raisedButton = { textColor: buttonColorHex, primaryTextColor: buttonColorHex };
          }
          if (secondaryColorHex) {
            newTheme.palette.secondary = { main: secondaryColorHex };
          }
          if (font) {
            const fontFamily = [
              font,
              theme.typography.fontFamily
                .split(',')
                .map(R.trim)
                .filter(x => x !== font)
            ].join(',');

            newTheme.typography = {
              fontFamily,
              ...R.pick(
                [
                  'htmlFontSize',
                  'fontSize',
                  'fontWeightLight',
                  'fontWeightRegular',
                  'fontWeightMedium',
                  'fontWeightBold'
                ],
                theme.typography
              )
            };
          }

          return createTheme(newTheme);
        }}>
        <div style={{ textAlign: 'center' }}>
          <Button
            size="large"
            onClick={this.notifyIframeOpenModal.bind(this)}
            variant="contained"
            color="secondary"
            style={{
              maxWidth: '240px',
              maxHeight: '64px',
              minWidth: '240px',
              minHeight: '64px',
              color: buttonColorHex ? buttonColorHex : '',
              fontSize: this.getFontSize()
            }}>
            {this.getBookNowString()}
          </Button>
        </div>
      </ThemeProvider>
    );
  }
}
