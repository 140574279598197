import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, TextField, CircularProgress } from '@material-ui/core';
import claimtip from '../../assets/images/payout_page/Money.svg';
import KYCCollect from '../../components/TapToTip/KYC';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  container: {},
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto'
  },
  paymentMethods: {
    border: '1px solid red'
  },
  outerSurface: {
    backgroundColor: '#f9f9f9',
    padding: 22,
    margin: 11,
    borderRadius: 16
  },
  innerSurface: {
    backgroundColor: 'white',
    padding: 12,
    paddingLeft: 12,
    borderRadius: 16
  },
  innerSurfaceButton: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    padding: 12,
    borderRadius: 16,
    marginBottom: '16px'
  },
  boldHeading: {
    color: 'black',
    fontWeight: 'bold'
  },
  imageStyle: {
    margin: 22
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonStyle: {
    padding: 8
  },
  iconImage: {
    width: '35px',
    height: '35px'
  },
  iconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    borderRadius: 35,
    marginRight: 12,
    width: '35px',
    height: '35px'
  },
  payoutLinkBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.22px',
    color: '#000'
  },
  iconAndLabel: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default function KYC() {
  const classes = useStyles();

  //If payout-link id in url display revolut payout links otherwise just display paypal
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Paper className={classes.outerSurface} elevation={0}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13 }}>
            <Paper className={classes.iconContainer} elevation={3}>
              <img src={claimtip} />
            </Paper>
            <div>
              <span className={classes.boldHeading}>Let's get you verified!</span> - Verified
              partners get access to faster uninterrupted payouts.
            </div>
          </Paper>
        </Paper>
        <br />
        <KYCCollect />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location = '/tipdirect';
          }}>
          Back to TipDirect
        </Button>
      </div>
    </div>
  );
}
