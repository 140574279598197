import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  transactions: [],
  error: null,
  isLoading: false
};

const fetchTransactions = state => ({
  ...state,
  isLoading: true
});

const fetchTransactionsSuccess = (state, transactions) => ({
  ...state,
  error: null,
  transactions,
  isLoading: false
});

const facedError = (state, error) => ({
  ...state,
  error
});

export default createReducer(
  {
    [actionTypes.FETCH_TRANSACTIONS]: fetchTransactions,
    [actionTypes.FETCH_TRANSACTIONS_SUCCESS]: fetchTransactionsSuccess,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
