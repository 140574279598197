import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const fetchAgentsProfiles = createAction(actionTypes.FETCH_AGENTS_PROFILES);
export const fetchAgentsProfilesSuccess = createAction(actionTypes.FETCH_AGENTS_PROFILES_SUCCESS);

export const sendInvitesToGuides = createAction(actionTypes.SEND_GUIDE_INVITES);
export const revokeInvite = createAction(actionTypes.REVOKE_INVITE);

export const fetchActiveTours = createAction(actionTypes.FETCH_ACTIVE_TOURS);
export const fetchActiveToursSuccess = createAction(actionTypes.FETCH_ACTIVE_TOURS_SUCCESS);

export const addToursToProfile = createAction(actionTypes.ADD_TOURS_TO_PROFILE);

export const facedError = createAction(actionTypes.ACTION_ERROR);
