import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const deleteResource = createAction(actionTypes.DELETE_RESOURCE);
export const fetchResources = createAction(actionTypes.FETCH_RESOURCES);
export const fetchResourcesSuccess = createAction(actionTypes.FETCH_RESOURCES_SUCCESS);
export const editResource = createAction(actionTypes.EDIT_RESOURCE);
export const createResource = createAction(actionTypes.CREATE_RESOURCE);

export const facedError = createAction(actionTypes.ACTION_ERROR);
