import { Analytics } from '@analytics';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ProPlanModal from '../ProPlanModal';
import messages from './messages';

const useStyles = makeStyles({
  button: {
    color: '#fff',
    fontSize: '14px',
    boxShadow: 'none',
    backgroundColor: '#6a96ff',
    whiteSpace: 'nowrap',
    height: 'fit-content',

    '&:hover': {
      backgroundColor: '#5f87e6'
    }
  },
  menuItemText: {
    marginRight: '8px'
  },
  proBadge: {
    fontSize: '10px!important',
    padding: '2px 8px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#4b4b4b',
    fontWeight: 600
  }
});

const AccessControlledButton = ({
  feature,
  tooltipTitle = '',
  as = 'Button',
  title,
  onClick,
  withIcon = true,
  icon = null,
  elementStyle = '',
  shouldCheckFeature = true
}) => {
  const intl = useIntl();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const classes = useStyles();
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isFeatureEnabled = useMemo(() => {
    if (shouldCheckFeature) {
      return isFeatureForCompanyEnabled(feature);
    }

    return true;
  }, [feature, isFeatureForCompanyEnabled, shouldCheckFeature]);
  const buttonIcon = withIcon ? icon ?? <AddCircleIcon /> : null;

  const openModal = () => {
    Analytics.track('Pro Plan Modal Opened', { feature, title });
    setIsOpenModal(true);
  };

  const ProBadge = () => <div className={classes.proBadge}>{intl.formatMessage(messages.pro)}</div>;

  const getContent = () => {
    switch (as) {
      case 'Button':
        return (
          <Button
            className={`${classes.button} ${elementStyle}`}
            variant="contained"
            color="primary"
            endIcon={isFeatureEnabled ? buttonIcon : <ProBadge />}
            onClick={isFeatureEnabled ? onClick : openModal}>
            {title}
          </Button>
        );
      case 'MenuItem':
        return (
          <MenuItem className={elementStyle} onClick={isFeatureEnabled ? onClick : openModal}>
            <span className={classes.menuItemText}>{title}</span>
            {!isFeatureEnabled ? <ProBadge /> : null}
          </MenuItem>
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      <Tooltip
        title={!isFeatureEnabled ? intl.formatMessage(messages.proPlanTooltip) : tooltipTitle}>
        {getContent()}
      </Tooltip>

      <ProPlanModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  );
};

export default AccessControlledButton;
