export const FETCH_AGENTS_PROFILES = 'FETCH_AGENTS_PROFILES';
export const FETCH_AGENTS_PROFILES_SUCCESS = 'FETCH_AGENTS_PROFILES_SUCCESS';

export const SEND_GUIDE_INVITES = 'SEND_GUIDE_INVITES';
export const REVOKE_INVITE = 'REVOKE_INVITE';

export const FETCH_ACTIVE_TOURS = 'FETCH_ACTIVE_TOURS';
export const FETCH_ACTIVE_TOURS_SUCCESS = 'FETCH_ACTIVE_TOURS_SUCCESS';

export const ADD_TOURS_TO_PROFILE = 'ADD_TOURS_TO_PROFILE';

export const ACTION_ERROR = 'ACTION_ERROR';
