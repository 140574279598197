import EmojiAvatar from '@components/EmojiAvatar';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';

const useStyles = makeStyles({
  profileImage: {
    width: '47px',
    height: '47px',
    marginTop: '4px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  guideInfoBlock: ({ isSelectedResource }) => ({
    display: 'grid',
    margin: '20px 0',
    gridTemplateColumns: '20% 80%',
    backgroundColor: isSelectedResource && '#f0f0f0'
  }),
  activeCards: ({ isActive }) => ({
    padding: '4px 12px',
    width: 'fit-content',
    borderRadius: '4px',
    backgroundColor: isActive ? '#d9ffd7' : '#dbdbdb',
    color: isActive ? '#12573e' : '#6a6b6b',
    fontWeight: 'bold',
    fontSize: '11px'
  }),

  profileName: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 600
  },
  profileInfo: {
    fontSize: '12px',
    color: '#000',
    fontWeight: 500,

    '& > *': {
      margin: '4px 0'
    }
  }
});

const ResourceOverview = ({ resource, selectedResource, handleSelectResource }) => {
  const { _id: resourceId, name, assignedCardsAmount } = resource;
  const classes = useStyles({
    isSelectedResource: selectedResource && selectedResource === resourceId,
    isActive: assignedCardsAmount > 0
  });
  const intl = useIntl();

  return (
    <Grid className={classes.guideInfoBlock} onClick={() => handleSelectResource(resourceId)}>
      <Grid className={classes.imageContainer}>
        <div className={classes.profileImage}>
          <EmojiAvatar code={resource.emoji || ':credit_card:'} />
        </div>
      </Grid>
      <Grid className={classes.profileInfo}>
        <div className={classes.profileName}>{name}</div>
        <div className={classes.activeCards}>
          {intl.formatMessage(messages.activeCards)}: {assignedCardsAmount}
        </div>
      </Grid>
    </Grid>
  );
};

export default ResourceOverview;
