import { Button, IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz as MoreHorizIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import React, { useState } from 'react';

const useStyles = makeStyles({
  manageButton: {
    marginRight: 5
  },
  iconOnlyButton: {
    width: '20px',
    height: '40px',
    borderRadius: '4px',
    '& .MuiSvgIcon-root': {
      color: '#d8d8d8',
      width: '35px',
      height: '35px'
    }
  }
});

const ButtonWithMenu = ({ menuBody, iconOnly = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleOpenDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!iconOnly && (
        <Button
          className={classes.manageButton}
          style={{ backgroundColor: '#fff' }}
          color="inherit"
          variant="contained"
          onClick={handleOpenDropDown}>
          <MoreHorizIcon />
        </Button>
      )}
      {iconOnly && (
        <IconButton className={classes.iconOnlyButton} onClick={handleOpenDropDown}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        style={{ marginTop: '50px' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropDown}>
        {menuBody.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithMenu;
