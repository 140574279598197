import { defineMessage } from 'react-intl';

export const scope = 'app.containers.TippingTeamPage';

export default defineMessage({
  resourceNotPartOfTeam: {
    id: `${scope}.resourceNotPartOfTeam`,
    defaultMessage: 'This resource is currently not part of a team.'
  },
  addResourceToTeam: {
    id: `${scope}.addResourceToTeam`,
    defaultMessage: 'Add this resource to your existing Tipping Team'
  },
  createNewTeamWithUser: {
    id: `${scope}.createNewTeamWithUser`,
    defaultMessage: 'Create a new tipping team using this user'
  },
  addUserToTeam: {
    id: `${scope}.addUserToTeam`,
    defaultMessage: 'Add this user to your existing Tipping Team'
  },
  createNewTeamWithResource: {
    id: `${scope}.createNewTeamWithResource`,
    defaultMessage: 'Create a new tipping team using this resource'
  },
  moveResourceToTeam: {
    id: `${scope}.moveResourceToTeam`,
    defaultMessage:
      'This resource is already part of a team. Add this resource to your existing Tipping Team'
  },
  resourceIsPartOfTeam: {
    id: `${scope}.resourceIsPartOfTeam`,
    defaultMessage: 'This resource is already part of your team.'
  },
  moveUserToNewTeam: {
    id: `${scope}.moveUserToNewTeam`,
    defaultMessage: 'This user is already part of a team. Create a new tipping team using this user'
  },
  moveUserToExistingTeam: {
    id: `${scope}.moveUserToExistingTeam`,
    defaultMessage:
      'This user is already part of a team. Add this user to your existing Tipping Team'
  },
  userIsPartOfTeam: {
    id: `${scope}.userIsPartOfTeam`,
    defaultMessage: 'This user is already part of your team.'
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add'
  },
  toMyTeam: {
    id: `${scope}.toMyTeam`,
    defaultMessage: 'to my team'
  },
  youOwnThisCard: {
    id: `${scope}.youOwnThisCard`,
    defaultMessage:
      'This card belongs to you. Scan a card belonging to a resource or another colleague to create a team'
  },
  claimResource: {
    id: `${scope}.claimResource`,
    defaultMessage: 'Claim Resource'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  leaveTeamConfirmation: {
    id: `${scope}.leaveTeamConfirmation`,
    defaultMessage: 'Are you sure you wish to leave this team?'
  },
  claimResourceConfirmation: {
    id: `${scope}.claimResourceConfirmation`,
    defaultMessage:
      'Are you sure you wish to proceed? Claiming this resource will create a new team. This action cannot be undone'
  },
  claimResourceInGroup: {
    id: `${scope}.claimResourceInGroup`,
    defaultMessage:
      'Are you sure you wish to proceed? Claiming this resource will add it to your team.'
  },
  addUserToTeamButton: {
    id: `${scope}.addUserToTeamButton`,
    defaultMessage: 'Add User To Team'
  },
  createNewTeam: {
    id: `${scope}.createNewTeam`,
    defaultMessage: 'Create New Team'
  }
});
