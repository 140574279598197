import { call, put, takeLatest } from '@redux-saga/core/effects';
import { fetchTransactions } from '../../../api/taptotip';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* fetchTransactionsWorker({ payload = null }) {
  try {
    const transactions = yield call(fetchTransactions, payload);

    yield put(actions.fetchTransactionsSuccess(transactions));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}
export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_TRANSACTIONS, fetchTransactionsWorker);
}
