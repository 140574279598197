import { SessionStorageKeys, SignupType } from '@constants';
import { isEmpty } from 'ramda';
import React from 'react';
import { Redirect, Route } from 'react-router';

function checkOnboardingFlow(user, path) {
  const hasIncompleteOnboardingSteps =
    user.profile.onboarding?.tipping == false || user.profile.onboarding?.profile == false;
  const interestedInTapToTip = user.profile.interestedIn === SignupType.TapToTip;
  const isNotOnboardingPage = path !== '/onboarding';
  //Note: Never interrupt with the user payout flow
  const isNotPayoutPage = path !== '/payoutlink' && path !== '/payoutlink-confirm';
  const skipAllSteps = sessionStorage.getItem(SessionStorageKeys.SkipAllSteps);

  if (
    hasIncompleteOnboardingSteps &&
    interestedInTapToTip &&
    isNotOnboardingPage &&
    isNotPayoutPage &&
    !skipAllSteps
  ) {
    return <Redirect to="/onboarding" />;
  }

  return null;
}

function checkRegion(user, props) {
  if (user.region && user.region.toLowerCase() !== process.env.REGION.toLowerCase()) {
    if (user.region.toLowerCase() === 'local' || process.env.REGION === 'LOCAL') {
      return true;
    }

    // If the server is QA we don't care about the users region.
    if (process.env.REGION === 'QA') {
      return true;
    }

    logoutAndChangeRegion(user.region.toLowerCase(), props.location.pathname);
    return false;
  }

  return true;
}

function logoutAndChangeRegion(region, path) {
  if (region === 'qa') {
    window.location.href = `https://qats.tripadmit.com${path}`;
    return;
  }

  if (region === 'local') {
    window.location.href = `localhost:3001${path}`;
  }

  window.location.href = `https://${region}.tip.direct${path}`;
  // fetch('/api/v1/user/logout', { method: 'POST' })
  //   .then(resp => resp.json())
  //   .then(function() {
  //     Analytics.logoutOfTracking();
  //     sessionStorage.removeItem(SessionStorageKeys.SkipAllSteps);
     
  //   });
}

export function PrivateRoute({ user, ...props }) {
  if (isEmpty(user)) {
    return <Redirect to="/signin" />;
  }

  if (!checkRegion(user, props)) return null;

  const onboardingRedirect = checkOnboardingFlow(user, props.path);

  if (onboardingRedirect) return onboardingRedirect;

  return <Route {...props} />;
}

export function TSUserRoute({ user, ...props }) {
  if (isEmpty(user)) {
    return <Redirect to="/tipdirect" />;
  }

  if (!checkRegion(user, props)) return null;

  const onboardingRedirect = checkOnboardingFlow(user, props.path);

  if (onboardingRedirect) return onboardingRedirect;

  return <Route {...props} />;
}
