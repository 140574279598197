import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.ResourceListItem';

export default defineMessages({
  activeCards: {
    id: `${scope}.activeCards`,
    defaultMessage: 'Active Cards'
  },
  editResource: {
    id: `${scope}.editResource`,
    defaultMessage: 'Edit'
  },
  deleteResource: {
    id: `${scope}.deleteResource`,
    defaultMessage: 'Delete'
  },
  downloadQRCode: {
    id: `${scope}.download_qr_code`,
    defaultMessage: 'Download QR Code'
  }
});
