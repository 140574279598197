import { Analytics } from '@analytics';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CustomizableModal from '../../Common/CustomizableModal';
import AddToursTable from '../AddToursTable';
import { filterCheckedTours, mapTours } from './helper';
import messages from './messages';

const useStyles = makeStyles({
  username: {
    fontWeight: 'bold',
    color: '#000',
    marginRight: 4
  },
  description: {
    marginRight: 4
  }
});

const AddToursModal = ({ isOpen, setIsOpen, activeTours, addToursToProfile, profile, userId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [allTours, setAllTours] = useState(mapTours(activeTours, profile?.tours));

  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    setAllTours(mapTours(activeTours, profile?.tours));
  }, [activeTours, profile?.tours]);

  const updateCheckedTours = (tourId, checked) => {
    setAllTours(prevTours =>
      prevTours.map(tour => (tour._id === tourId ? { ...tour, checked } : tour))
    );
  };

  const getModalDescription = () => {
    if (profile?.userId && userId !== profile?.userId) {
      return (
        <div>
          <span className={classes.description}>
            {intl.formatMessage(messages.descriptionWithUser)}:
          </span>
          <span className={classes.username}>{profile.firstName || ''}</span>
          <span className={classes.username}>{profile.lastName || ''}</span>
        </div>
      );
    } else {
      return intl.formatMessage(messages.description);
    }
  };

  return (
    <CustomizableModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      body={<AddToursTable tours={allTours} updateCheckedTours={updateCheckedTours} />}
      handleFooterCloseButton={closeModal}
      handleHeaderCloseButton
      onClose={closeModal}
      title={intl.formatMessage(messages.title)}
      desc={getModalDescription()}
      primaryActionButtonData={{
        isDisabled: false,
        onClick: () => {
          addToursToProfile(filterCheckedTours(allTours));
          if (allTours.length > 0) {
            Analytics.track('saved tours to profile', {}, true);
          }
          closeModal();
        },
        content: intl.formatMessage(messages.save)
      }}
    />
  );
};

export default AddToursModal;
