import { defineMessages } from 'react-intl';

export const scope = 'app.components.BlankSlates.Resources';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Resources'
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage: 'An easy way to collect more tips'
  },
  text: {
    id: `${scope}.text`,
    defaultMessage:
      'Resources are an easy and quick way to pair multiple cards together. Once you create a resource, users can join the resource and form a group. Tips collected in the group will be split and dividied among the people in the group.'
  },
  createResource: {
    id: `${scope}.createResource`,
    defaultMessage: 'Create Resouce'
  },

});
