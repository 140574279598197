import { Analytics } from '@analytics';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import FlagIcon from '@assets/images/tip_direct/Flag.svg';
import LightningIcon from '@assets/images/tip_direct/LightningBolt.png';
import ProPlanModal from '../ProPlanModal';
import messages from './messages';

const useStyles = makeStyles({
  emptyBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '72px',
    gap: '16px'
  },
  emptyBodyInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000',
    maxWidth: '200px'
  },
  emptyBodyTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  emptyBodyDescription: {
    fontSize: '14px',
    fontWeight: 600
  },
  upgradeButton: {
    height: '53px',
    backgroundColor: '#ffffff',
    width: '237px',
    margin: '11px 12px',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '0',
    borderImageSource: 'linear-gradient(to bottom, #00d8ff, #df3fc8)',
    borderImageSlice: '1',
    backgroundImage:
      'linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #00d8ff, #df3fc8)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    color: '#000000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px'
  },
  lightningIcon: {
    width: '24px',
    height: '24px',
    marginRight: '4px'
  }
});

const AccessControlledTableBody = ({ feature, title, description = '' }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isFeatureEnabled = React.useMemo(() => isFeatureForCompanyEnabled(feature), [
    feature,
    isFeatureForCompanyEnabled
  ]);

  const handleUpgradeClick = () => {
    Analytics.track('Pro Plan Modal Opened via AccessControlledTableBody', {
      feature
    });
    setIsOpenModal(true);
  };

  return (
    <div className={classes.emptyBody}>
      <img src={FlagIcon} alt="Flag" />
      <div className={classes.emptyBodyInfo}>
        <span className={classes.emptyBodyTitle}>{title}</span>
        {description && <span className={classes.emptyBodyDescription}>{description}</span>}
      </div>
      {!isFeatureEnabled && (
        <Button className={classes.upgradeButton} onClick={handleUpgradeClick}>
          <img
            src={LightningIcon}
            alt="Lightning"
            className={`${classes.icon} ${classes.lightningIcon}`}
          />
          <span>{intl.formatMessage(messages.upgradeButton)}</span>
        </Button>
      )}

      <ProPlanModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </div>
  );
};

export default AccessControlledTableBody;
