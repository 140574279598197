import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const CountryDropdown = ({ updateCountry, availableCountries, allCountries, error, ...fieldProps }) => {
  const intl = useIntl();

  const hasRestricedCountry = allCountries && Object.keys(availableCountries).indexOf(fieldProps.value.toLowerCase()) === -1;
  const countryList = hasRestricedCountry ? allCountries : availableCountries

  return (
    <Grid item xs={12}>
      <Autocomplete
        required
        options={Object.keys(countryList)}
        value={fieldProps.value}
        disabled={hasRestricedCountry}
        getOptionLabel={option => countryList[option]}
        filterOptions={(options, { inputValue }) =>
          options.filter(option =>
            countryList[option].toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            required
            label="Country"
            variant="outlined"
            error={error}
            helperText={error ? intl.formatMessage(messages.countryError) : ''}
            {...fieldProps}
          />
        )}
        onChange={updateCountry}
      />
    </Grid>
  );
};

export default CountryDropdown;
