import { Analytics } from '@analytics';
import { Button, Chip, TextField } from '@material-ui/core';
import { capitalize } from '@material-ui/core/utils';
import { makeStyles } from '@material-ui/styles';
import { lensPath, lensProp, set } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReviewSite } from '../../../containers/TipDirectPages/TipDirectTours/constants';
import { languageCodesToLanguageNames, supportedLanguages } from '../../../utils/helpers';
import CustomizableModal from '../../Common/CustomizableModal';
import messages from './messages';

const useStyles = makeStyles(() => ({
  modalBody: {
    width: '80vw',
    maxWidth: '470px'
  },
  input: {
    width: '100%',
    margin: '20px 0 0 0',
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    }
  },
  title: {
    fontSize: '18px',
    color: '#555555',
    margin: '20px 0 0'
  },
  description: {
    color: '#555',
    fontSize: '16px',
    fontWeight: 'normal',
    margin: '16px 0'
  },
  reviewSourceBlock: {
    margin: '10px 0 0'
  },
  chip: {
    borderRadius: '6px',
    fontWeight: '600',
    margin: '8px 4px',
    border: 'solid 0.8px #d4d1d1',
    color: '#d4d1d1'
  },
  chipActive: {
    border: 'solid 0.8px #6997ff',
    color: '#6997ff',
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.13)'
  }
}));

const emptyState = {
  primaryLanguage: 'en',
  title: '',
  desc: '',
  [ReviewSite.Google]: {
    name: ReviewSite.Google,
    url: ''
  },
  [ReviewSite.TripAdvisor]: {
    name: ReviewSite.TripAdvisor,
    url: ''
  },
  [ReviewSite.Yelp]: {
    name: ReviewSite.Yelp,
    url: ''
  }
};

function getInitialState(tourDetails) {
  if (tourDetails) {
    return {
      primaryLanguage: tourDetails.primaryLanguage,
      title: tourDetails.title,
      desc: tourDetails.description,
      [ReviewSite.Google]: {
        name: ReviewSite.Google,
        url: tourDetails?.reviewSites?.find(
          i => i.name.toLowerCase() == ReviewSite.Google.toLowerCase()
        )?.url
      },
      [ReviewSite.TripAdvisor]: {
        name: ReviewSite.TripAdvisor,
        url: tourDetails?.reviewSites?.find(
          i => i.name.toLowerCase() == ReviewSite.TripAdvisor.toLowerCase()
        )?.url
      },
      [ReviewSite.Yelp]: {
        name: ReviewSite.Yelp,
        url: tourDetails?.reviewSites?.find(
          i => i.name.toLowerCase() == ReviewSite.Yelp.toLowerCase()
        )?.url
      }
    };
  }
  return emptyState;
}

const CreateTourModal = ({
  isOpen,
  setIsOpen,
  addTapToTipTour,
  updateTapToTipTour,
  tourDetails,
  setIsManageGuidesOpen
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const isEditMode = updateTapToTipTour && tourDetails;

  const [state, setState] = useState(getInitialState(tourDetails));

  useEffect(() => {
    if (isOpen) {
      setState(getInitialState(tourDetails));
    }
  }, [tourDetails, isOpen]);

  function handleClose() {
    setIsOpen(false);
    setState(getInitialState());
  }

  function handleChange(lens) {
    return event => {
      const newState = set(lens, event.target.value, state);
      setState(newState);
    };
  }

  const getModalBody = () => {
    return (
      <div className={classes.modalBody}>
        <h3 className={classes.title}>
          {isEditMode
            ? intl.formatMessage(messages.updateTour)
            : intl.formatMessage(messages.createTour)}
        </h3>
        <TextField
          className={classes.input}
          label={intl.formatMessage(messages.tourName)}
          variant="outlined"
          value={state.title}
          onChange={handleChange(lensProp('title'))}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          multiline
          className={classes.input}
          label={intl.formatMessage(messages.longDesc)}
          variant="outlined"
          value={state.desc}
          rows={5}
          onChange={handleChange(lensProp('desc'))}
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className={classes.reviewSourceBlock}>
          <TextField
            className={classes.input}
            label={intl.formatMessage(messages.googleReviewURL)}
            variant="outlined"
            value={state[ReviewSite.Google].url}
            onChange={handleChange(lensPath([ReviewSite.Google, 'url']))}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className={classes.reviewSourceBlock}>
          <TextField
            className={classes.input}
            label={intl.formatMessage(messages.tripAdvisorReviewURL)}
            variant="outlined"
            value={state[ReviewSite.TripAdvisor].url}
            onChange={handleChange(lensPath([ReviewSite.TripAdvisor, 'url']))}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className={classes.reviewSourceBlock}>
          <TextField
            className={classes.input}
            label={intl.formatMessage(messages.yelpReviewURL)}
            variant="outlined"
            value={state[ReviewSite.Yelp].url}
            onChange={handleChange(lensPath([ReviewSite.Yelp, 'url']))}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        {!isEditMode && (
          <>
            <h3 className={classes.title}>{intl.formatMessage(messages.inputLanguage)}</h3>
            <h4 className={classes.description}>
              {intl.formatMessage(messages.inputLanguageDesc)}
            </h4>

            <div>
              {supportedLanguages.map(lang => {
                const isActive = lang === state.primaryLanguage;
                return (
                  <Chip
                    data-active={isActive}
                    className={`${classes.chip} ${isActive && classes.chipActive}`}
                    key={lang}
                    variant="outlined"
                    onClick={() =>
                      handleChange(lensProp('primaryLanguage'))({ target: { value: lang } })
                    }
                    color={isActive ? 'primary' : 'default'}
                    label={capitalize(languageCodesToLanguageNames[lang])}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };

  function handleConfirm() {
    const payload = {
      displayName: state.title,
      description: state.desc,
      primaryLanguage: state.primaryLanguage,
      reviewSites: [
        {
          name: state[ReviewSite.TripAdvisor].name,
          url: state[ReviewSite.TripAdvisor].url
        },
        {
          name: state[ReviewSite.Google].name,
          url: state[ReviewSite.Google].url
        },
        {
          name: state[ReviewSite.Yelp].name,
          url: state[ReviewSite.Yelp].url
        }
      ]
    };

    if (isEditMode) {
      updateTapToTipTour({
        id: tourDetails._id,
        ...payload
      });
    } else {
      addTapToTipTour(payload);
    }

    Analytics.trackUseFeature('Tours');
    Analytics.track(`TTT tour ${isEditMode ? 'edited' : 'created'}`);
    handleClose();
  }

  const handleManageGuidesClicked = () => {
    handleClose();
    Analytics.track('Manage Guides button clicked');
    setIsManageGuidesOpen(true);
  };

  const getCustomButtons = () => {
    const manageGuidesButton = (
      <Button onClick={handleManageGuidesClicked} variant="contained" color="primary">
        {intl.formatMessage(messages.manageGuides)}
      </Button>
    );
    return [manageGuidesButton];
  };

  const isConfirmButtonEnabled =
    state.title.length > 3 &&
    state.desc.length > 3 &&
    (state[ReviewSite.TripAdvisor].url ||
      state[ReviewSite.Google].url ||
      state[ReviewSite.Yelp].url);

  return (
    <CustomizableModal
      isOpen={isOpen}
      onClose={handleClose}
      withHeader={false}
      body={getModalBody()}
      handleFooterCloseButton={handleClose}
      customButtons={isEditMode ? getCustomButtons() : []}
      primaryActionButtonData={{
        isDisabled: !isConfirmButtonEnabled,
        onClick: handleConfirm,
        content: isEditMode
          ? intl.formatMessage(messages.updateTour)
          : intl.formatMessage(messages.createTour)
      }}
    />
  );
};

export default CreateTourModal;
