import { call, put, takeLatest } from '@redux-saga/core/effects';
import { SEND_RECOMMEND_TO_A_FRIEND } from './types';
import { sendInvitesForGuides } from '../../../../api/taptotip';
import { setSnackbarStatus } from '../../../../containers/StatusBar/actions';
import { extractStatusBarError, snackbarStatus } from '../../../../containers/StatusBar';

function* sendRecommendToAFriendWorker({ payload }) {
  try {
    yield call(sendInvitesForGuides, payload);
    yield put(setSnackbarStatus(snackbarStatus.successfulEmailSent));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* leftMenuSagaWatcher() {
  yield takeLatest(SEND_RECOMMEND_TO_A_FRIEND, sendRecommendToAFriendWorker);
}
