import { Analytics } from '@analytics';
import AppleStoreIcon from '@assets/images/AppStore.png';
import AndroidStoreIcon from '@assets/images/PlayStore.png';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  appPromoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  appPromoteHeading: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#000',
    whiteSpace: 'nowrap'
  },
  appPromoteSubHeading: {
    fontSize: '12px',
    fontWeight: '700',
    color: '#b3aeae'
  },
  appPromoteLinks: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '11px',
  },
  appPromoteLinkTitle: {
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    flexGrow: '1'
  },
  appPromoteLink: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#f2f2f2',
    alignItems: 'center',
    borderRadius: '7.5px',
    padding: '4px 3px',
    cursor: 'pointer',
    color: '#777777',
    '&:not(:first-child)': {
      marginTop: '9px'
    },
    border: 'none',
    '&:hover': {
      backgroundColor: '#E9E9E9'
    }
  }
}));

export const appStoreLinks = {
  android:
    'https://play.google.com/store/apps/details?id=com.tripadmit.TipDirect&pcampaignid=web_share',
  apple: 'https://apps.apple.com/ie/app/tipdirect/id6497329965'
};

const AppPromotion = source => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.appPromoteContainer}>
      <div className={classes.appPromoteHeading}>{intl.formatMessage(messages.heading)}</div>
      <div className={classes.appPromoteSubHeading}>{intl.formatMessage(messages.subHeading)}</div>
      <div className={classes.appPromoteLinks}>
        <button
          className={classes.appPromoteLink}
          onClick={() => {
            Analytics.track('apple app store link clicked', { page: source });
            window.location.replace(appStoreLinks.apple);
          }}>
          <img src={AppleStoreIcon} alt={'Apple App Store Icon'} />
        </button>
        <button
          className={classes.appPromoteLink}
          onClick={() => {
            Analytics.track('android app store link clicked', { page: source });
            window.location.replace(appStoreLinks.android);
          }}>
          <img src={AndroidStoreIcon} alt={'Google Play Icon'} />
        </button>
      </div>
    </div>
  );
};

export default AppPromotion;
