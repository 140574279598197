import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const fetchProfile = createAction(actionTypes.FETCH_PROFILE);
export const fetchProfileSuccess = createAction(actionTypes.FETCH_PROFILE_SUCCESS);

export const updateProfile = createAction(actionTypes.UPDATE_PROFILE);
export const updateProfileSuccess = createAction(actionTypes.UPDATE_PROFILE_SUCCESS);

export const uploadUserProfileImage = createAction(actionTypes.UPLOAD_USER_PROFILE_IMAGE);
export const uploadUserProfileImageSuccess = createAction(
  actionTypes.UPLOAD_USER_PROFILE_IMAGE_SUCCESS
);

export const connectStripe = createAction(actionTypes.CONNECT_STRIPE);
export const connectStripeSuccess = createAction(actionTypes.CONNECT_STRIPE_SUCCESS);

export const fetchActiveTours = createAction(actionTypes.FETCH_ACTIVE_TOURS);
export const fetchActiveToursSuccess = createAction(actionTypes.FETCH_ACTIVE_TOURS_SUCCESS);

export const addToursToProfile = createAction(actionTypes.ADD_TOURS_TO_PROFILE);

export const getPayPalLoginUrl = createAction(actionTypes.GET_PAYPAL_LOGIN_URL);
export const getPayPalLoginUrlSuccess = createAction(actionTypes.GET_PAYPAL_LOGIN_URL_SUCCESS);

export const paypalLoginCallback = createAction(actionTypes.PAYPAL_LOGIN_CALLBACK);
export const paypalLoginCallbackSuccess = createAction(actionTypes.PAYPAL_LOGIN_CALLBACK_SUCCESS);

export const facedError = createAction(actionTypes.ACTION_ERROR);

export const fetchAgentsProfiles = createAction(actionTypes.FETCH_AGENTS_PROFILES);
export const fetchAgentsProfilesSuccess = createAction(actionTypes.FETCH_AGENTS_PROFILES_SUCCESS);
