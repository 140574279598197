import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../../containers/TipDirectPages/TippingProfile/messages';
import { RevolutCurrencies } from '../../../utils/constants';
import { relevantCurrencies, tipDirectCountries } from '../../../utils/helpers';
import TooltipWrapper from '../../Common/TooltipWrapper';
import CountryDropdown from '../../CountryDropdown';
import PaymentOptionsSwitcher from '../../TapToTip/PaymentOptionsSwitcher';

const useStyles = makeStyles({
  validationErrorMessage: {
    color: 'red'
  },
  textField: {
    marginTop: 20,
    width: '100%',
    minWidth: '250px',
    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    }
  },
  paymentOptions: {
    marginTop: 20
  }
});

const TippingInformation = ({ profile, errors, touched, values, validateSchema }) => {
  const intl = useIntl();
  const classes = useStyles();
  const isRevolutCurrencyEnabled = Boolean(profile?.revolut?.currency);

  const Fields = {
    Currency: 'currency',
    Country: 'country'
  };

  const getFieldContent = (form, field) => {
    switch (field) {
      case Fields.Currency: {
        return (
          <TooltipWrapper
            shouldShowTooltip={isRevolutCurrencyEnabled}
            title={intl.formatMessage(messages.currencyTooltip)}>
            <TextField
              id="standard-select-currency"
              disabled={isRevolutCurrencyEnabled}
              select
              label={intl.formatMessage(messages.paymentLabel)}
              variant="outlined"
              className={classes.textField}
              value={profile?.revolut?.currency || values.currency}
              onChange={e => form.setFieldValue('currency', e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}>
              {Object.entries({
                ...relevantCurrencies,
                ...(isRevolutCurrencyEnabled && { ...RevolutCurrencies })
              }).map(([key, symbol]) => (
                <MenuItem key={key} value={key}>
                  {symbol}
                </MenuItem>
              ))}
            </TextField>
          </TooltipWrapper>
        );
      }
      case Fields.Country:
        return (
          <CountryDropdown
            id="standard-select-country"
            updateCountry={(_, value) => form.setFieldValue('country', value)}
            availableCountries={tipDirectCountries}
            value={values.country ?? ''}
            label={intl.formatMessage(messages.countryLabel)}
            className={classes.textField}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {Object.values(Fields).map(field => (
        <div key={field}>
          <Field name={field}>
            {({ form }) => {
              const fieldContent = getFieldContent(form, field);
              return fieldContent;
            }}
          </Field>
          {errors[field] && touched[field] && (
            <div className={classes.validationErrorMessage}>{errors[field]}</div>
          )}
        </div>
      ))}

      <div className={classes.paymentOptions}>
        <PaymentOptionsSwitcher
          mergedProfile={profile}
          validateSchema={validateSchema}
          enableSwitching={false}
        />
      </div>
    </div>
  );
};

export default TippingInformation;
