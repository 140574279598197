import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../../utils/constants';
import CustomizableModal from '../../Common/CustomizableModal';
import AddGuidesTable from '../AddGuidesTable';
import messages from './messages';

const mapGuides = (guides, tourId) => {
  const activeGuides = guides.filter(guide => guide.type !== 'invite');

  return activeGuides.map(guide => {
    if (guide.tours.length) {
      const guideToursIds = guide.tours.map(({ _id }) => _id);

      return { ...guide, checked: guideToursIds.includes(tourId) };
    }

    return { ...guide, checked: false };
  });
};

const useStyles = makeStyles(theme => ({
  modalBody: {
    width: '100vw',
    maxWidth: '800px',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      maxWidth: '100%'
    }
  }
}));

const ManageGuidesModal = ({
  isOpen,
  setIsOpen,
  tourDetails,
  guides,
  multipleAssign,
  setIsAddTourModalOpen
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [allGuides, setAllGuides] = useState([]);
  const hasAssignChanged =
    tourDetails &&
    JSON.stringify(mapGuides(guides, tourDetails?._id)) !== JSON.stringify(allGuides);

  useEffect(() => {
    if (guides && guides.length && tourDetails) {
      setAllGuides(mapGuides(guides, tourDetails._id));
    }
  }, [guides, tourDetails]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleBack = () => {
    setIsOpen(false);
    setIsAddTourModalOpen(true);
  };

  const updateCheckedGuides = (guideId, checked) => {
    setAllGuides(prevGuides =>
      prevGuides.map(guide => (guide.userId === guideId ? { ...guide, checked } : guide))
    );
  };

  const getModalBody = () => {
    return (
      <div className={classes.modalBody}>
        <AddGuidesTable guides={allGuides} updateCheckedGuides={updateCheckedGuides} />;
      </div>
    );
  };

  const handleConfirm = () => {
    const checkedGuides = allGuides.reduce((prev, curr) => {
      if (curr.checked) return [...prev, curr.userId];

      return prev;
    }, []);

    multipleAssign([tourDetails._id], checkedGuides);
    handleClose();
  };

  return (
    <CustomizableModal
      isOpen={isOpen}
      onClose={handleClose}
      body={getModalBody()}
      handleFooterCloseButton={handleBack}
      title={tourDetails?.title}
      desc={intl.formatMessage(messages.description)}
      primaryActionButtonData={{
        isDisabled: !hasAssignChanged,
        onClick: handleConfirm,
        content: intl.formatMessage(messages.updateTour)
      }}
    />
  );
};

export default ManageGuidesModal;
