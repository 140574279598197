import { createReducer } from '@utils/helpers';
import * as actionTypes from './types';

const initialState = {
  teamDetails: null,
  isLoading: false,
  isSplitBreakdownSaved: false,
  isSplitBreakdownSaving: false
};

const fetchTeamDetails = state => ({
  ...state,
  isLoading: true,
  isSplitBreakdownSaved: false,
  isSplitBreakdownSaving: false
});

const fetchTeamDetailsSuccess = (state, teamDetails) => ({
  ...state,
  teamDetails,
  isLoading: false
});

const saveSplitBreakdown = state => ({
  ...state,
  isSplitBreakdownSaved: false,
  isSplitBreakdownSaving: true
});

const saveSplitBreakdownSuccess = state => ({
  ...state,
  isSplitBreakdownSaved: true,
  isSplitBreakdownSaving: false
});

const saveSplitBreakdownFailure = state => ({
  ...state,
  isSplitBreakdownSaved: false,
  isSplitBreakdownSaving: false
});

const clearTeamDetails = state => ({
  ...state,
  teamDetails: null
});

export default createReducer(
  {
    [actionTypes.FETCH_TEAM_DETAILS]: fetchTeamDetails,
    [actionTypes.FETCH_TEAM_DETAILS_SUCCESS]: fetchTeamDetailsSuccess,
    [actionTypes.SAVE_SPLIT_BREAKDOWN]: saveSplitBreakdown,
    [actionTypes.SAVE_SPLIT_BREAKDOWN_SUCCESS]: saveSplitBreakdownSuccess,
    [actionTypes.SAVE_SPLIT_BREAKDOWN_FAILURE]: saveSplitBreakdownFailure,
    [actionTypes.CLEAR_TEAM_DETAILS]: clearTeamDetails
  },
  initialState
);
