import axios from 'axios';
import { analyticsSignUpHandler } from '../../../utils/analytics';
import { SignupType } from '../../../utils/constants';
import { ApiError } from '../../error';

const baseUrl = '/api/v1/tipdirect';
const url = `${baseUrl}/invites`;

export const signUpFromInvite = (id, data) => {
  return axios
    .post(`${url}/sign-up-from-invite/${id}`, data)
    .then(res => res.data.data)
    .then(user => {
      analyticsSignUpHandler(user, SignupType.TapToTip);
      return user;
    })
    .catch(ApiError.ofAndThrow);
};

export const fetchInviteInfo = id => {
  return axios
    .get(`${url}/${id}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchOpenInviteInfo = id => {
  return axios
    .get(`${baseUrl}/invite/${id}`, {
      params: {
        includeCompanyName: true
      }
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchInviteInfoByEmail = email => {
  return axios
    .get(`${url}/all/${email}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const sendInvitesForGuides = payload => {
  return axios
    .post(`${url}/send`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const revokeInvite = inviteId => {
  return axios
    .post(`${url}/revoke/${inviteId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
