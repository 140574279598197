import { defineMessages } from 'react-intl';

const scope = 'app.components.TipDirect.PaymentOptionsSwitcher';

export default defineMessages({
  defaultPaymentAmountHeader: {
    id: `${scope}.profile.defaultPaymentAmount.header`,
    defaultMessage: 'Default Payment Amount'
  },
  defaultPaymentAmountLabel: {
    id: `${scope}.profile.defaultPaymentAmount.label`,
    defaultMessage: 'Default Payment Amount'
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save & Set as Default'
  },
  quickTipAmounts: {
    id: `${scope}.quickTipAmounts`,
    defaultMessage: 'Quick Tip Amounts'
  },
  bespokeAmount: {
    id: `${scope}.bespokeAmount`,
    defaultMessage: 'Bespoke Amount'
  },
  letCustomersKnowQuick: {
    id: `${scope}.letCustomersKnowQuick`,
    defaultMessage:
      'Let customers know how much to tip you, your great tip should be the amount you would ideally like to be paid.'
  },
  good: {
    id: `${scope}.good`,
    defaultMessage: 'Good'
  },
  great: {
    id: `${scope}.great`,
    defaultMessage: 'Great'
  },
  amazing: {
    id: `${scope}.amazing`,
    defaultMessage: 'Amazing'
  },
  letCustomersKnowBespoke: {
    id: `${scope}.letCustomersKnowBespoke`,
    defaultMessage:
      'Let customers know how much to tip you, your default tip amount should be how much you would ideally like to be paid.'
  },
  preselectedAmount: {
    id: `${scope}.preselectedAmount`,
    defaultMessage: 'Preselected Amount'
  }
});
