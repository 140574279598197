import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ResourceOverview from './ResourceOverView';
import messages from './messages';

const useStyles = makeStyles({
  profiles: {
    borderTop: 'solid 1px #d9d9d9',
    borderBottom: 'solid 1px #d9d9d9',
    padding: '20px',
    height: '350px',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '0px',
      paddingRight: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e7e7e7',
      width: '8px',
      borderRadius: '4px'
    }
  },
  selectResourceText: {
    color: '#000',
    fontSize: '20px'
  },
  selectResourceInfo: {
    fontSize: '13px',
    color: '#a6a6a6'
  },
  search: {
    width: '100%',
    height: '50px',
    borderRadius: '4px',
    fontSize: '14px',
    marginTop: '16px',
    marginBottom: '16px'
  },
  checkbox: {
    margin: '16px 0',
    '& .MuiFormControlLabel-label': {
      fontSize: '13px',
      fontWeight: 600,
      color: '#000'
    }
  },
  searchButton: {
    padding: 0,

    '& .MuiSvgIcon-root': {
      fontSize: '32px',
      color: '#d4d1d1'
    }
  },
  emptyData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  assignToCard: {
    width: '100%'
  }
});

const AssignCardToResource = ({ resources, setSelectedResource, selectedResource }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showNoActiveCards, setShowNoActiveCards] = useState(false);

  const classes = useStyles();
  const intl = useIntl();

  const handleCheckboxChange = event => {
    setSelectedResource(null);
    setShowNoActiveCards(event.target.checked);
  };
  const handleSearch = event => {
    setSelectedResource(null);
    setSearchTerm(event.target.value);
  };

  const handleSelectResource = resourceId => {
    setSelectedResource(resourceId);
  };

  const filteredResources = resources.filter(({ name, assignedCardsAmount }) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const isNoActiveCards = assignedCardsAmount === 0;

    return (
      name.toLowerCase().includes(lowerCaseSearchTerm) &&
      (showNoActiveCards ? isNoActiveCards : true)
    );
  });

  return (
    <div className={classes.assignToCard}>
      <h2 className={classes.selectResourceText}>{intl.formatMessage(messages.selectResource)}</h2>
      <div className={classes.selectResourceInfo}>
        {intl.formatMessage(messages.selectResourceInfo)}
      </div>
      <TextField
        type="text"
        label={intl.formatMessage(messages.searchLabel)}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        variant="outlined"
        value={searchTerm}
        className={classes.search}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.searchButton}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox checked={showNoActiveCards} onChange={handleCheckboxChange} color="primary" />
        }
        label={intl.formatMessage(messages.checkboxLabel)}
      />
      <div className={classes.profiles}>
        {filteredResources.length ? (
          filteredResources.map((resource, index) => (
            <ResourceOverview
              resource={resource}
              key={index}
              selectedResource={selectedResource}
              handleSelectResource={handleSelectResource}>
              {resource.name}
            </ResourceOverview>
          ))
        ) : (
          <div className={classes.emptyData}>
            {intl.formatMessage(messages.emptyDataSourceMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignCardToResource;
