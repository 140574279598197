import {
  createResource,
  deleteResource,
  editResource,
  fetchTipDirectResources
} from '@api/taptotip/resource';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* fetchTipDirectResourcesWorker() {
  try {
    const toursResponse = yield call(fetchTipDirectResources);
    yield put(actions.fetchResourcesSuccess(toursResponse));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* deleteResourceWorker({ payload }) {
  try {
    yield call(deleteResource, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield fetchTipDirectResourcesWorker();
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.details)));
  }
}

function* editResourceWorker({ payload }) {
  try {
    yield call(editResource, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield fetchTipDirectResourcesWorker();
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.details)));
  }
}

function* createResourceWorker({ payload }) {
  try {
    yield call(createResource, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield fetchTipDirectResourcesWorker();
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.details)));
  }
}

export default function* tapToTipWatcher() {
  yield takeLatest(types.FETCH_RESOURCES, fetchTipDirectResourcesWorker);
  yield takeLatest(types.DELETE_RESOURCE, deleteResourceWorker);
  yield takeLatest(types.EDIT_RESOURCE, editResourceWorker);
  yield takeLatest(types.CREATE_RESOURCE, createResourceWorker);
}
