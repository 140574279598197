import { Analytics } from '@analytics';
import InfoIcon from '@assets/images/onboarding/Info.svg';
import SplitIcon from '@assets/images/Split.svg';
import TeamTipSplitDetails from '@components/TapToTip/Groups/TeamTipSplitDetails';
import { CardViewType } from '@constants';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getSortedGuidesForTeam } from '@utils/helpers';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32
  },
  infoBox: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    padding: '15.3px 14.8px 11.7px 21px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    backgroundColor: '#fff',
    gap: '8px',
    alignItems: 'flex-start'
  },
  infoIcon: {
    width: '24px',
    height: '24px'
  },
  infoBoxText: {
    fontSize: '11px',
    fontWeight: 600,
    color: '#a0a0a0'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000'
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#a0a0a0'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px'
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    flex: 1
  },
  icon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  splitTipBlock: {
    padding: '17px 20px 18px 26px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0',
    margin: 'auto',
    width: '100%'
  },
  actionButtons: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  button: {
    width: '100%',
    maxWidth: '354px',
    height: '39px',
    backgroundColor: '#6997ff',
    color: 'white',

    '&:hover': {
      backgroundColor: '#6982ff'
    },

    '&:disabled': {
      backgroundColor: '#dadbdb',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#ccc'
      }
    }
  },
  cancelButton: {
    backgroundColor: '#e0e3e0',
    color: '#a8a8a8',

    '&:hover': {
      backgroundColor: '#a0a0a0',
      color: '#fff'
    }
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  splitEvenButton: {
    float: 'right',
    maxWidth: '45px',
    height: '30px',
    fontSize: '12px',
    minWidth: '45px'
  }
});

const MAX_SPLIT_PERCENTAGE = 100;

const TeamSettings = ({
  groupId,
  setCardView,
  isLoading,
  teamDetails,
  usersInfo,
  fetchTeamDetails,
  saveSplitBreakdown,
  isSplitBreakdownSaved,
  isSplitBreakdownSaving,
  currentUser,
  clearTeamDetails
}) => {
  useInjectSaga({ key: 'teamSettings', saga });
  useInjectReducer({ key: 'teamSettings', reducer });

  const classes = useStyles();
  const intl = useIntl();
  const [tipBreakdown, setTipBreakdown] = React.useState([]);

  const totalBreakdown = useMemo(() => {
    return tipBreakdown.reduce((acc, curr) => acc + curr.splitPercentage, 0);
  }, [tipBreakdown]);

  const isSplitPercentageEqual = useMemo(() => {
    const firstTipPercentage = tipBreakdown[0]?.splitPercentage;
    return tipBreakdown.every(user => user.splitPercentage === firstTipPercentage);
  }, [tipBreakdown]);

  const filteredUsers = useMemo(() => {
    if (!teamDetails || !usersInfo) {
      return [];
    }

    return usersInfo.filter(user => teamDetails?.users.includes(user._id));
  }, [teamDetails, usersInfo]);

  const sortedUsers = useMemo(() => {
    if (!filteredUsers.length) return [];

    const currentUserId = currentUser.userId;

    return getSortedGuidesForTeam(filteredUsers, currentUserId);
  }, [filteredUsers, currentUser]);

  useEffect(() => {
    if (filteredUsers.length) {
      // fill tipBreakdown with values from teamDetails.userSplitTip
      // and set the split percentage to 0 if it's not set
      setTipBreakdown(
        filteredUsers.map(user => ({
          userId: user._id,
          splitPercentage:
            teamDetails.userTipSplit.tipBreakdown.find(userTip => userTip.userId === user._id)
              ?.splitPercentage ?? 0
        }))
      );
    }
  }, [teamDetails, filteredUsers]);

  useEffect(() => {
    if (!teamDetails && !isLoading) {
      fetchTeamDetails(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (isSplitBreakdownSaved) {
      setCardView(CardViewType.TeamDetails);
    }
  }, [isSplitBreakdownSaved]);

  useEffect(() => {
    Analytics.trackViewFeature('TeamSplitSettings');
  }, []);

  const handleSplitPercentageChange = useCallback(({ userId, splitPercentage }) => {
    setTipBreakdown(prev =>
      prev.map(user => (user.userId === userId ? { ...user, splitPercentage } : user))
    );
  }, []);

  const handleEvenSplitClicked = () => {
    Analytics.track('Split Tips Evenly Clicked');
    const eventSplit = 100 / filteredUsers.length;
    setTipBreakdown(prev =>
      prev.map(user => {
        return { ...user, splitPercentage: eventSplit };
      })
    );
  };

  const handleSaveSplitBreakdown = useCallback(() => {
    Analytics.track('SplitTipSaved', { groupId });
    Analytics.trackUseFeature('TeamSplitSettings');

    saveSplitBreakdown({
      groupId,
      tipBreakdown: tipBreakdown.map(user => ({
        userId: user.userId,
        splitPercentage: user.splitPercentage
      }))
    });
  });

  const handleCancel = useCallback(() => {
    // clear up unsaved changes
    setTipBreakdown([]);

    // clear team details before showing the tipping team, to fetch the latest data
    clearTeamDetails();
    setCardView(CardViewType.TeamDetails);
  });

  if (isLoading) {
    return (
      <div className={`${classes.container} ${classes.loading}`}>
        <CircularProgress />
      </div>
    );
  }

  if (!teamDetails) return null;

  return (
    <div className={classes.container}>
      <div className={classes.infoBox}>
        <img src={InfoIcon} className={classes.infoIcon} alt="Split Tip" />
        <span className={classes.infoBoxText}>
          {intl.formatMessage(
            isSplitPercentageEqual ? messages.infoBoxTextEqual : messages.infoBoxTextNotEqual
          )}
        </span>
      </div>

      <div className={classes.splitTipBlock}>
        <div className={classes.topContainer}>
          <div className={classes.icon}>
            <img src={SplitIcon} alt="Split Tip" />
          </div>
          <div className={classes.headerText}>
            <div className={classes.title}>{intl.formatMessage(messages.splitYourTips)}</div>
            <div className={classes.subTitle}>
              {intl.formatMessage(messages.setYourSplitPercentage)}
            </div>
          </div>
          <Button
            className={`${classes.splitEvenButton} ${classes.button}`}
            onClick={handleEvenSplitClicked}
            title="Split tips evenly">
            50/50
          </Button>
        </div>

        {sortedUsers.map(user => (
          <TeamTipSplitDetails
            key={user._id}
            avatarUrl={user.profile.avatarUrl}
            firstName={user.profile.firstName}
            lastName={user.profile.lastName}
            isTheSameUser={currentUser.userId === user._id}
            tipBreakdown={tipBreakdown}
            userId={user._id}
            onSplitPercentageChange={handleSplitPercentageChange}
          />
        ))}
      </div>
      <div className={classes.actionButtons}>
        <Button
          className={classes.button}
          disabled={isSplitBreakdownSaving || totalBreakdown !== MAX_SPLIT_PERCENTAGE}
          onClick={handleSaveSplitBreakdown}>
          {intl.formatMessage(messages.save)}
        </Button>
        <Button className={`${classes.button} ${classes.cancelButton}`} onClick={handleCancel}>
          {intl.formatMessage(messages.cancel)}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.teamSettings?.isLoading,
  teamDetails: state.teamSettings?.teamDetails,
  isSplitBreakdownSaved: state.teamSettings?.isSplitBreakdownSaved,
  isSplitBreakdownSaving: state.teamSettings?.isSplitBreakdownSaving,
  user: state.auth?.user
});

const mapDispatchToProps = dispatch => ({
  fetchTeamDetails: groupId => dispatch(actions.fetchTeamDetails(groupId)),
  saveSplitBreakdown: splitBreakdown => dispatch(actions.saveSplitBreakdown(splitBreakdown)),
  clearTeamDetails: () => dispatch(actions.clearTeamDetails())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSettings);
