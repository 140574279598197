import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const fetchTeamDetails = createAction(actionTypes.FETCH_TEAM_DETAILS);
export const fetchTeamDetailsSuccess = createAction(actionTypes.FETCH_TEAM_DETAILS_SUCCESS);
export const clearTeamDetails = createAction(actionTypes.CLEAR_TEAM_DETAILS);

export const saveSplitBreakdown = createAction(actionTypes.SAVE_SPLIT_BREAKDOWN);
export const saveSplitBreakdownSuccess = createAction(actionTypes.SAVE_SPLIT_BREAKDOWN_SUCCESS);
export const saveSplitBreakdownFailure = createAction(actionTypes.SAVE_SPLIT_BREAKDOWN_FAILURE);
