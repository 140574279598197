import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.GroupCard';

export default defineMessages({
  team: {
    id: `${scope}.team`,
    defaultMessage: 'Tipping Team'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Members'
  }
});
