import { createAction } from '../../utils/helpers';
import * as actionTypes from './types';

export const assignCardToUser = createAction(actionTypes.ASSIGN_CARD_TO_USER);
export const assignCardToUserSuccess = createAction(actionTypes.ASSIGN_CARD_TO_USER_SUCCESS);

export const fetchUsers = createAction(actionTypes.FETCH_USERS);
export const fetchUsersSuccess = createAction(actionTypes.FETCH_USERS_SUCCESS);
export const fetchTipDirectResources = createAction(actionTypes.FETCH_TIPDIRECT_RESOURCES);
export const fetchTipDirectResourcesSuccess = createAction(
  actionTypes.FETCH_TIP_DIRECT_RESOURCES_SUCCESS
);
export const actionError = createAction(actionTypes.ACTION_ERROR);
