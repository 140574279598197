import { createTheme } from '@material-ui/core/styles';
import MontserratWOFF2 from '../assets/fonts/Montserrat-Regular.woff2';

import { StyleBreakpoints } from '../utils/constants';
const Montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
        local('Montserrat'),
        local('Montserrat-Regular'),
        url(${MontserratWOFF2}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

const theme = createTheme({
  palette: {
    text: {
      primary: '#767777',
      secondary: '#767777'
    },
    primary: {
      main: '#6997ff',
      dark: '#555555',
      light: '#f3f3f3',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#6997ff',
      dark: '#555555',
      light: '#f3f3f3',
      contrastText: '#ffffff'
    },
    background: {
      default: '#fff'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    fontFamily: 'Montserrat, Arial',
    button: {
      textTransform: 'capitalize',
      fontWeight: 'bold'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Montserrat]
      }
    },
    'MuiContainer-root': {
      margin: 'unset',
      width: 'unset'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: +StyleBreakpoints.md,
      lg: +StyleBreakpoints.lg,
      xl: 1536
    }
  }
});

export default theme;
