import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Flag as FlagIcon } from '@material-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import AccessControlledButton from '../AccessControlledButton';
import messages from './messages';

const useStyles = makeStyles({
  toursContainer: {
    minHeight: `72px`,
    display: 'flex',
    alignItems: 'center',
    padding: '25px 15px',
    margin: '32px auto',
    borderRadius: '11px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    width: '90%'
  },
  container: {
    marginRight: '20px'
  },
  headerText: {
    fontSize: 24,
    color: '#000',
    fontWeight: 'bold'
  },
  details: {
    fontSize: 14,
    color: '#a6a6a6',
    fontWeight: 500,
    marginBottom: '20px'
  },
  subHeaderText: {
    fontSize: '14px',
    fontWeight: '500'
  },
  selectButton: {
    width: '100%'
  },
  tourName: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#555',
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',

    '&:last-of-type': {
      marginBottom: '0'
    }
  },
  tours: {
    display: 'flex',
    flexDirection: 'column'
  },
  flagIcon: {
    backgroundColor: '#f1f1f1',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function GuideTours({ tours, setIsOpen }) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerText}>{intl.formatMessage(messages.tours)}</div>
      <div className={classes.details}>{intl.formatMessage(messages.toursSubHeader)}</div>
      <div className={classes.subHeaderText}>{intl.formatMessage(messages.yourTours)}</div>
      <Paper className={classes.toursContainer}>
        {tours?.length ? (
          <div className={classes.tours}>
            {tours.map((tour, index) => {
              return (
                <div key={index} className={classes.tourName}>
                  <div className={classes.flagIcon}>
                    <FlagIcon />
                  </div>
                  {tour.title}
                </div>
              );
            })}
          </div>
        ) : (
          <div>{intl.formatMessage(messages.toursEmptyStateMessage)}</div>
        )}
      </Paper>
      <AccessControlledButton
        feature={'PostTipReviewCollection'}
        title={intl.formatMessage(messages.selectTours)}
        onClick={() => setIsOpen(true)}
        elementStyle={classes.selectButton}
        withIcon={false}
      />
    </div>
  );
}

export default GuideTours;
