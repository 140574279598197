import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AssignCardToResource';

export default defineMessages({
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  activeCards: {
    id: `${scope}.active_cards`,
    defaultMessage: 'Active Cards'
  },
  selectResource: {
    id: `${scope}.select_resource`,
    defaultMessage: 'Select Resource'
  },
  selectResourceInfo: {
    id: `${scope}.select_resource_info`,
    defaultMessage:
      'Search or select a resource you would like to assign the card to from the list below'
  },
  searchLabel: {
    id: `${scope}.search_label`,
    defaultMessage: 'Search'
  },
  searchPlaceholder: {
    id: `${scope}.search_placeholder`,
    defaultMessage: 'Enter Name'
  },
  checkboxLabel: {
    id: `${scope}.checkbox_label`,
    defaultMessage: 'Only show resources with no active cards'
  },
  emptyDataSourceMessage: {
    id: `${scope}.empty_data_source_message`,
    defaultMessage: 'No records to display'
  }
});
