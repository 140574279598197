import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/payout/';

export const AddCounterparty = profile => {
  return axios
    .post(`${url}revolut/counterparty`, profile)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const userHasRevolut = userId => {
  return axios
    .get(`${url}revolut/counterparty/${userId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const RemitPaymentIntentIfAble = paymentIntentId => {
  return axios
    .post(`${url}remitt-payment-intent`, {
      id: paymentIntentId
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const RemitBalance = () => {
  return axios
    .post(`${url}remitt-balance`, {})
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const RemitPayPalPaymentIntentIfAble = paymentIntentId => {
  return axios
    .post(`${url}remitt-paypal-payment-intent`, {
      id: paymentIntentId
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const getPayoutLink = userId => {
  return axios
    .get(`${url}link`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const createVenmoAccount = venmoPhone => {
  return axios
    .post(`${url}venmo/user`, {
      venmoPhone: venmoPhone
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const RemitVenmoPaymentIntentIfAble = paymentIntentId => {
  return axios
    .post(`${url}remitt-paypal-payment-intent`, {
      id: paymentIntentId
    })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
