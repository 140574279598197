import { Analytics } from '@analytics';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { regionalURLs } from '../../../utils/constants';
import AccessControlledButton from '../AccessControlledButton';
import AddToursModal from '../AddToursModal';
import messages from './messages';

const useStyles = makeStyles({
  manageButton: {
    marginRight: 5
  },
  menu: {
    '& .MuiPaper-root': {
      width: '288px',
      right: '90px',
      left: 'unset !important',
      borderRadius: '11px'
    },
    '& .MuiList-root': {
      padding: '0 !important'
    },

    '& .MuiButtonBase-root': {
      fontSize: '18px',
      justifyContent: 'center',
      height: '71px',
      color: '#555555',
      fontWeight: '600'
    }
  }
});

const AgentProfileSummaryButton = ({
  activeTours,
  profile,
  addToursToProfile,
  userId,
  handleQRDownload,
  setShouldShowCopyAlert
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const intl = useIntl();

  const linkUrl = (regionalURLs[process.env.REGION] || regionalURLs.US) + `t/g/${userId}`;
  const previewLinkUrl = `${linkUrl}?preview=true`;

  const handleOpenDropDown = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const handleCopyTipLinkClick = () => {
    navigator.clipboard.writeText(linkUrl);
    setShouldShowCopyAlert(true);
    Analytics.track('Users table - copied tip link');
  };

  const handleQRDownloadClick = () => {
    handleQRDownload(linkUrl, profile.firstName, profile.lastName);
    Analytics.track('download guide qr code clicked', {
      page: 'users table - agent summary button'
    });
  };

  const handlePreviewTippingClick = () => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: previewLinkUrl
    }).click();
    Analytics.track('Users table - opened tipping preview');
  };

  const handleAddToursClick = () => {
    setIsOpen(true);
    Analytics.track('open add tours to guide');
  };

  return (
    <div>
      <Button
        className={classes.manageButton}
        style={{ backgroundColor: '#fff' }}
        variant="contained"
        onClick={handleOpenDropDown}>
        <MoreHorizIcon />
      </Button>
      <Menu
        style={{ marginTop: '50px' }}
        anchorEl={anchorEl}
        keepMounted
        className={classes.menu}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropDown}>
        <MenuItem onClick={handleCopyTipLinkClick}>
          <FormattedMessage {...messages.copyTipLink} />
        </MenuItem>
        <MenuItem onClick={handleQRDownloadClick}>
          <FormattedMessage {...messages.downloadQRCode} />
        </MenuItem>
        <MenuItem onClick={handlePreviewTippingClick}>
          <FormattedMessage {...messages.previewTippingFlow} />
        </MenuItem>
        <AccessControlledButton
          elementStyle={classes.menuItem}
          feature={'PostTipReviewCollection'}
          as="MenuItem"
          title={intl.formatMessage(messages.addToursToProfile)}
          onClick={handleAddToursClick}
        />
      </Menu>

      <AddToursModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeTours={activeTours}
        profile={profile}
        userId={userId}
        addToursToProfile={tourIds => addToursToProfile(profile.userId, tourIds)}
      />
    </div>
  );
};

export default AgentProfileSummaryButton;
