import { Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px'
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  name: {
    marginLeft: '16px',
    flex: 1
  },
  textField: {
    width: 80,

    '& > div': {
      height: 32,
      borderRadius: 6,
      paddingRight: 8,
      color: '#a0a0a0',
      fontWeight: 600
    }
  },
  inputBox: {
    marginLeft: 'auto'
  },
  label: {
    fontSize: '10px',
    fontWeight: '600',
    color: '#a8a8a8',
    width: 18
  }
});

const TeamTipSplitDetails = ({
  avatarUrl,
  firstName,
  lastName,
  isTheSameUser,
  tipBreakdown,
  userId,
  onSplitPercentageChange
}) => {
  const classes = useStyles();
  const intl = useIntl();

  // current user's split percentage
  const splitPercentage = useMemo(
    () => tipBreakdown.find(user => user.userId === userId)?.splitPercentage ?? 0,
    [tipBreakdown]
  );

  // sum of other users' split percentage
  const otherUsersSplitPercentage = useMemo(
    () =>
      tipBreakdown.reduce(
        (acc, { userId: id, splitPercentage }) => acc + (id !== userId ? splitPercentage : 0),
        0
      ),
    [tipBreakdown, userId]
  );

  const handlePercentageChange = event => {
    let newValue = parseFloat(event.target.value);

    // Max allowable value for the current user based on others' percentages
    const remaining = parseFloat((100 - otherUsersSplitPercentage).toFixed(2));

    // Ensure newValue does not exceed remaining
    if (newValue > remaining) {
      newValue = remaining;
    }

    // If incremented with arrow key and there's a remaining fraction part (like .67), ensure it stops at the max
    if (newValue > remaining || newValue < 0) {
      return;
    }

    onSplitPercentageChange({ userId, splitPercentage: newValue });
  };

  return (
    <div className={classes.container}>
      <div className={classes.userInfo}>
        {avatarUrl ? (
          <img className={classes.icon} src={avatarUrl} alt={`${firstName} ${lastName}'s avatar`} />
        ) : (
          <AccountCircleIcon className={classes.icon} />
        )}
        <div className={classes.name}>
          {`${firstName} ${lastName}${isTheSameUser ? ' (you)' : ''}`}
        </div>
      </div>
      <Box className={classes.inputBox}>
        <TextField
          label={<Box className={classes.label}>{intl.formatMessage(messages.split)}</Box>}
          type="number"
          InputProps={{
            endAdornment: '%',
            step: 1 // Step set to 1 for arrow increments
          }}
          value={splitPercentage}
          variant="outlined"
          className={classes.textField}
          onChange={handlePercentageChange}
        />
      </Box>
    </div>
  );
};

export default TeamTipSplitDetails;
