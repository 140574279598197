import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Groups.TeamTipSplitDetails';

export default defineMessages({
  split: {
    id: `${scope}.split`,
    defaultMessage: 'Split'
  }
});
