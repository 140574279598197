import { Analytics } from '@analytics';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EyeIcon from '../../assets/images/eye.svg';
import Logo from '../../components/Nav/TopNav/Logo';
import ResetPasswordInfo, {
  ResetPasswordInfoFontColor,
  ResetPasswordInfoIcon,
  ResetPasswordInfoStatus
} from '../../components/ResetPasswordInfo';
import { Copyright } from '../../components/SignInForm';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: '18px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  sendButton: {
    margin: theme.spacing(3, 0, 2)
  },
  notFound: {
    display: 'grid',
    gridTemplateColumns: '20% 80%',
    backgroundColor: '#fdecea',
    fontWeight: 500,
    borderRadius: '2px',
    width: '100%',
    marginBottom: '12px'
  },
  text: {
    color: ResetPasswordInfoFontColor.ERROR,
    fontSize: '12px'
  },
  imgBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '25px'
  },
  expiredErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px'
  },
  expiredButton: {
    marginTop: '8px',
    alignSelf: 'flex-start'
  }
}));

const ResetPasswordPage = ({
  fetchResetPasswordToken,
  updatePassword,
  resetPasswordToken,
  history,
  error
}) => {
  useInjectReducer({ key: 'resetPassword', reducer });
  useInjectSaga({ key: 'resetPassword', saga });

  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const resetToken = queryParams.get('token');

  const classes = useStyles();
  const intl = useIntl();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const ResetPasswordInfoText = {
    DEFAULT: intl.formatMessage(messages.defaultInfoText),
    PASSWORDS_DO_NOT_MATCH: intl.formatMessage(messages.passwordsDoNotMatch),
    PASSWORD_DOES_NOT_MATCH_GUIDE: intl.formatMessage(messages.passwordDoesNotMatchGuide)
  };

  useEffect(() => {
    if (!resetPasswordToken) {
      fetchResetPasswordToken(resetToken);
    }
  }, [resetToken]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const isValidPassword = password => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#-.])[A-Za-z\d@$!%*?&#-.]{8,}$/;

    return passwordRegex.test(password);
  };

  const handleUpdatePassword = () => {
    if (!isValidPassword(password)) {
      setPasswordError(ResetPasswordInfoText.PASSWORD_DOES_NOT_MATCH_GUIDE);
    } else if (password.trim() !== repeatPassword.trim()) {
      setPasswordError(ResetPasswordInfoText.PASSWORDS_DO_NOT_MATCH);
    } else {
      updatePassword(resetPasswordToken.token, password.trim(), history);
    }

    Analytics.track('Update password button clicked');
  };

  const handleNavigateToForgotPassword = () => {
    history.push('/forgot-password');
  };

  const handleShowError = () => {
    if (!error) {
      return <p className={classes.text}>{intl.formatMessage(messages.tokenNotFound)}</p>;
    }

    if (error.status) {
      return (
        <div className={classes.expiredErrorContainer}>
          <p className={classes.text}>{intl.formatMessage(messages[error.status])}</p>
          <Button
            className={classes.expiredButton}
            variant="contained"
            color="primary"
            onClick={handleNavigateToForgotPassword}>
            {intl.formatMessage(messages.forgotPasswordButton)}
          </Button>
        </div>
      );
    }

    return <p className={classes.text}>{intl.formatMessage(messages.unknownError)}</p>;
  };

  return (
    <div>
      <Helmet>
        <title>{intl.formatMessage(messages.resetPasswordTitle)}</title>
        <meta name="description" content="TripAdmit | Reset Password" />
      </Helmet>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          {!resetPasswordToken ? (
            <div className={classes.notFound}>
              <div className={classes.imgBlock}>
                <img
                  className={classes.image}
                  src={ResetPasswordInfoIcon.ERROR}
                  alt={'Info Icon'}
                />
              </div>

              {handleShowError()}
            </div>
          ) : (
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label={intl.formatMessage(messages.newPasswordLabel)}
                    value={password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            <img
                              src={EyeIcon}
                              alt="Eye icon"
                              style={{
                                filter: showPassword ? 'brightness(50%)' : 'brightness(100%)'
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type={showRepeatPassword ? 'text' : 'password'}
                    label={intl.formatMessage(messages.repeatPasswordLabel)}
                    value={repeatPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleRepeatPassword} edge="end">
                            <img
                              src={EyeIcon}
                              alt="Eye icon"
                              style={{
                                filter: showRepeatPassword ? 'brightness(50%)' : 'brightness(100%)'
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onChange={e => setRepeatPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ResetPasswordInfo
                    text={passwordError ?? ResetPasswordInfoText.DEFAULT}
                    status={
                      passwordError
                        ? ResetPasswordInfoStatus.ERROR
                        : ResetPasswordInfoStatus.DEFAULT
                    }
                  />
                </Grid>
              </Grid>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleUpdatePassword}
                className={classes.sendButton}>
                {intl.formatMessage(messages.resetPasswordTitle)}
              </Button>
            </form>
          )}
        </div>
      </Container>

      <Box>
        <Copyright />
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  resetPasswordToken: state.resetPassword?.resetPasswordToken,
  error: state.resetPassword?.error
});

const mapDispatchToProps = dispatch => ({
  fetchResetPasswordToken: token => dispatch(actions.fetchResetToken(token)),
  updatePassword: (token, password, history) =>
    dispatch(actions.updatePassword({ token, password, history }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage);
