import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.AccessControlledTableBody';

export default defineMessages({
  upgradeButton: {
    id: `${scope}.upgradeButton`,
    defaultMessage: 'Upgrade to Pro'
  }
});
