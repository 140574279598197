import { call, put } from 'redux-saga/effects';
import { currentUser, getCompanyLanguage, signin, signup, signupWithSubscription } from '../../api';
import { changeLocale } from '../../containers/LanguageProvider/actions';
import {
  initUserLoading,
  initUserSigninFailed,
  initUserSignupFailed,
  setCurrentUser
} from '../../store/user/actionCreators';
import { SignupType } from '../../utils/constants';

export function* updateSystemLanguage() {
  try {
    const language = yield call(getCompanyLanguage);
    yield put(changeLocale(language));
  } catch (error) {
    console.error(error);
  }
}

export function* signinSaga(action) {
  try {
    yield put(initUserLoading());
    yield call(signin, action.payload);

    yield call(fetchCurrentUserSaga);
    yield call(updateSystemLanguage);

    if (action.targetLocation) {
      if (action.targetLocation === '/reset-password') {
        action.history.push('/');
      } else {
        action.history.push(action.targetLocation);
      }
    }
  } catch (error) {
    yield put(initUserSigninFailed(error.message, history.location?.search));
    console.error(error);
  }
}

export function* signupSaga(action) {
  try {
    yield put(initUserLoading());

    action.signupWithSubscription
      ? yield call(signupWithSubscription, { ...action.payload, checkoutId: action.checkoutId })
      : yield call(signup, action.payload);

    yield call(fetchCurrentUserSaga);
    yield call(updateSystemLanguage);

    if (action.payload.targetLocation && !action.payload.targetLocation.includes('/t/c/')) {
      action.history.push(action.payload.targetLocation);
      return;
    }

    if (action.payload.signupType === SignupType.TapToTip) {
      action.history.push('/tipdirect');
      return;
    }

    action.history.push('/tipdirect');
  } catch (error) {
    console.log(error);
    yield put(initUserSignupFailed(error.message));
  }
}

export function* fetchCurrentUserSaga() {
  try {
    const user = yield call(currentUser);
    yield put(setCurrentUser(user.data.data));
  } catch (error) {
    console.error(error);
    yield put(setCurrentUser({}));
  }
}

export function* signOutSaga(action) {
  yield put(setCurrentUser({}));
}
